import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import BgImg from '../../images/backdrops/posterPlainBg.png'
import config from '../../config'
import Amplitude from '../../utils/amplitudeHelper'
import logos from '../../images/backdrops/poster-logos.png'
import header from '../../images/backdrops/poster-header.png'
import EntryForm from '../../components/EntryForm'
import routes from '../../routes'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'

const MobileContest = ({ currentUser }) => {
  const history = useHistory()
  const [ showSuccess, setShowSuccess ] = useState(false)

  useEffect(() => {
    history.push(routes.lobby)
    toast("Photo Contest is now closed. Photo Voting will be available soon.")
    // Amplitude.visit('Contest Form')
  }, [currentUser.id])

  useEffect(() => {
    document.title = `${config.eventName} | Photo Contest`
  }, [])

  return (
    <Page>
      <div className="outer-wrapper">
        <div className="container">
          <img className='my-2' width="100%" src={logos} alt="page-header"/>
          <img className='mb-5' width="100%" src={header} alt="page-header"/>
          {
            !showSuccess ?
            <>
              <EntryForm setShowSuccess={setShowSuccess} />
            </>
              :
              <div className="message">
                <h5 className="mb-4 font-weight-bolder">Thank you for your participation.</h5>
                <p>You will receive a confirmation email if your submission is accepted. </p>
              </div>
            }
        </div>
      </div>
    </Page>
  )
}

const Page = styled.div`
  background: url(${BgImg});
  background-size: cover;
  background-position: top center;

  height: calc(100% - 60px);
  width: 100vw;
  border-radius: 0px 0px 25px 25px;
  position: absolute;
  overflow-y: scroll;


  .outer-wrapper {
    width: 100%;
  }
  .message {
    text-align: center;
    background-color: rgba(255, 255, 255, 0.8);
    color: #05093A;
    padding: 36px 24px;
    font-weight: 500;
    width: 90%;
    margin: 0 auto;
  }
  .container {
    width: 90%;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 36px;
  }

  @media screen and (min-width: 540px) {
    .outer-wrapper {
      width: 600px;
      margin: auto;
    }
  }
`

export default MobileContest
