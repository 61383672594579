import React, { useMemo, useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import Media from 'react-media'

import config from './config'
import routes from './routes'
import { SIZES } from './styles/theme'
import EntrancePages from './containers/EntrancePages'
import PrivateRoute from './containers/PrivateRoute'

import CountdownPage from './pages/CountdownPage'
import SetPasswordFormPage from './pages/registration/SetPasswordFormPage'
import RegisterFormPage from './pages/registration/RegisterFormPage'
import PostRegisterPage from './pages/registration/PostRegisterPage'

import LobbyPage from './webOnlyPages/LobbyPage'
import AuditoriumPage from './webOnlyPages/AuditoriumPage'
import BasicBoothPage from './webOnlyPages/BasicBoothPage'
import ContestPage from './webOnlyPages/ContestPage'
import ContestFormPage from './webOnlyPages/ContestFormPage'

import MobileExpoBooth from './mobileOnlyPages/MobileExpo/MobileExpoBooth.js'
import MobileContest from './mobileOnlyPages/MobileContest/index.js'
import MobileContestForm from './mobileOnlyPages/MobileContest/MobileContestForm.js'
import MobileLobby from './mobileOnlyPages/MobileLobby/index.js'
import MobileMenu from './mobileOnlyPages/MobileMenu/index.js'
import MobileNotifications from './mobileOnlyPages/MobileNotifications/index.js'
import MobileAuditorium from './mobileOnlyPages/MobileAuditorium'
import ActiveUserCountPage from './pages/ActiveUserCountPage'
import UserSessionPage from './pages/UserSessionPage'
import PosterGalleryPage from './webOnlyPages/PosterGalleryPage'
import { MobilePhotoGallery } from './mobileOnlyPages/MobilePhotoGallery'
import BestPhotoPage from './webOnlyPages/BestPhotoPage'
import VotingPage from './webOnlyPages/VotingPage'
import MobileVoting from './mobileOnlyPages/MobileVoting/MobileVoting'
import MobileVotingForm from './mobileOnlyPages/MobileVotingForm/MobileVotingForm'

function App() {

  useEffect(() => {
    document.title = config.eventName
  }, [])

  useEffect(() => {
    window.chaport.q('hideLauncher')
  }, [])

  return useMemo(() => {
    return (
      <>
        {/* Registration */}
        <Switch>
          <Route exact path={routes.register} component={RegisterFormPage} />
          <Route exact path={routes.postRegister} component={PostRegisterPage} />
          <Route exact path={routes.root} component={CountdownPage} />

          <Route exact path="/realtime" component={ActiveUserCountPage} />
          <Route exact path="/sessions" component={UserSessionPage} />
          <Route exact path={routes.resetPassword} component={SetPasswordFormPage} />
          <Route exact path={routes.login} component={EntrancePages} />
        </Switch>

        <Media query={`(max-width: ${SIZES.mobileBreakpoint})`}>
          {(matches) =>
            matches ? (
              // MOBILE ROUTES
              <Switch>
                <PrivateRoute path={routes.lobby} component={MobileLobby} mobileNav />
                <PrivateRoute
                  mobileNav
                  path={routes.auditorium1}
                  component={(props) => {
                    return <MobileAuditorium {...props} hallNumber={1} liveUrl="https://vimeo.com/event/2297419/embed?autoplay=1" />}}
                />
                <PrivateRoute path={routes.booth} component={MobileExpoBooth} mobileNav />
                <PrivateRoute path={routes.menu} component={MobileMenu} mobileNav />
                <PrivateRoute path={routes.notifications} component={MobileNotifications} mobileNav />

                <PrivateRoute exact path={routes.votingform} component={MobileVotingForm} mobileNav />
                <PrivateRoute path={routes.voting} component={MobileVoting} mobileNav />
                <PrivateRoute path={routes.gallerium} component={MobilePhotoGallery} mobileNav />
              </Switch>
            ) : (
              // DESKTOP ROUTES
              <Switch>
                <PrivateRoute fixedNav path={routes.lobby} component={LobbyPage} />

                {/* Auditorium */}
                <PrivateRoute
                  fixedNav
                  path={routes.auditorium1}
                  component={(props) => {
                    return <AuditoriumPage {...props} hallNumber={1} liveUrl="https://vimeo.com/event/2297419/embed?autoplay=1" />}}
                />

                <PrivateRoute fixedNav path={routes.booth} component={BasicBoothPage} />
                <PrivateRoute exact fixedNav path={routes.photoform} component={ContestFormPage} />
                <PrivateRoute fixedNav path={routes.photo} component={ContestPage} />
                <PrivateRoute exact fixedNav path={routes.votingform} component={VotingPage} />
                <PrivateRoute fixedNav path={routes.voting} component={BestPhotoPage} />
                <PrivateRoute fixedNav path={routes.gallerium} component={PosterGalleryPage} />
              </Switch>
            )
          }
        </Media>
      </>
    )
  }, [])
}

export default App
