import React, { useEffect } from 'react'
import styled from 'styled-components'
import PageWithBg from '../components/PageWithBg'
import config from '../config'
import Amplitude from '../utils/amplitudeHelper'
import poster from '../images/backdrops/poster.png'
import routes from '../routes'
import closeIcon from '../images/icons/close.png'
import { Link, useHistory } from 'react-router-dom'
import { SIZES } from '../styles/theme'
import { toast } from 'react-toastify'

const PageWrapper = styled.div`
  #overlay {
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(15px);
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    #poster-container {
      position: absolute;
      width: 85%;
      /* top: 50%; */
      margin-top: ${SIZES.navHeight};
      left: 50%;
      transform: translateX(-50%);
      #show-form {
        /* border: 1px solid red; */
        position: absolute;
        top: 57.5%;
        bottom: 35.5%;
        left: 37.7%;
        right: 41.3%;
        cursor: pointer;
      }
    }
  }

  .toggle-back {
    font-weight: bold;
    color: #fff;
    background: transparent;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
    text-decoration: none;
    img {
      filter: invert(1);
      width: 35px;
      opacity: 0.5;
    }
    .back-prev {
      display: flex;
      width: 30px;
      flex-direction: column;
      transition: 2000ms;
      img {
        width: 26px;
      }
      .icon-label {
        text-align: center;
        font-size: 1px;
        opacity: 0;
      }
      &:hover {
        img {
          transform: scale(0.6);
        }
        .icon-label {
          font-size: 8px;
          opacity: 1;
        }
      }
    }
  }
`

export default function ContestPage({ currentUser }) {
  const history = useHistory()

  useEffect(() => {
    document.title = `${config.eventName} | Photo Contest`
  }, [])

  useEffect(() => {
    history.push(routes.lobby)
    toast("Photo Contest is now closed. Photo Voting will be available soon.")
    // Amplitude.visit('Photo Contest')
  }, [currentUser.id])


  return (
    <PageWrapper>
      <PageWithBg bgImg={config.assets.lobby.landscape}>
        <div id="overlay">
          <div id="poster-container">
            <Link to={routes.lobby} className="toggle-back">
              <div className="back-prev">
                <img src={closeIcon} alt="close-icon" />
                <small className="icon-label">close</small>
              </div>
            </Link>
            <img src={poster} width="100%" alt="contest poster"/>
            <Link id="show-form" to={routes.photoform}></Link>
          </div>
        </div>
      </PageWithBg>
    </PageWrapper>
  )
}
