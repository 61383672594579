import React from 'react'
import styled from 'styled-components'

const LoveStyle = styled.div`
  background-color: #134068;
  position: absolute;
  top: 2px;
  right: 2px;
  min-width: 25%;
  padding: 1px 3px;
  display: flex;
  border-radius: 24px;
  color: #fff;
  justify-content: space-around;
  align-items: center;
  svg {
    width: 10px;
    height: 10px;
  }
`

function EntryVote({ entry }) {
  
  return (
    <LoveStyle>
      <p className="m-0">{entry?.total_votes}</p>
      <svg width="35" height="33.654" viewBox="0 0 35 33.654">
        <path d="M73.577,56h-.084A9.573,9.573,0,0,0,65.5,60.375,9.573,9.573,0,0,0,57.507,56h-.084A9.512,9.512,0,0,0,48,65.507a20.48,20.48,0,0,0,4.022,11.165C57.087,83.6,65.5,89.654,65.5,89.654S73.913,83.6,78.978,76.672A20.48,20.48,0,0,0,83,65.507,9.512,9.512,0,0,0,73.577,56Zm3.5,19.284c-3.584,4.905-9.044,9.432-11.577,11.409A64.627,64.627,0,0,1,53.923,75.275a18.151,18.151,0,0,1-3.567-9.768,7.138,7.138,0,0,1,7.084-7.143h.076a7.054,7.054,0,0,1,3.458.909,7.352,7.352,0,0,1,2.558,2.4,2.364,2.364,0,0,0,3.954,0,7.425,7.425,0,0,1,2.558-2.4,7.054,7.054,0,0,1,3.458-.909h.076a7.138,7.138,0,0,1,7.084,7.143A18.38,18.38,0,0,1,77.077,75.284Z" 
          transform="translate(-48 -56)" 
          fill="#01d0b7"
        />
      </svg>
    </LoveStyle>
  
  )
}
export { EntryVote }

