import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'
import { COLORS, SIZES } from '../../styles/theme'
import config from '../../config'
// import logo from '../../images/event-logo.png'
// import banner from '../../images/registration/bannerL.png'
// import bannerM from '../../images/registration/bannerP.png'

const Page = styled.div`
  min-height: 100vh;
  height: 100vh;
  background-image: url(${config.assets.registration.landscape});
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  color: #FFFFFF;
  padding-bottom: 4.5em;
  padding-top: 3em;

  .mobile-show {
    display: none;
  }
  .brand {
    width: 80%;
    min-height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  #message {
    background-color: rgba(255, 255, 255, 0.80);
    border: none;
    width: 50%;
    color: #000;
    margin: 0 auto;
    padding: 1.5rem 2rem;
    text-align: center;
    .icon {
      width: 100px;
      margin: 0 auto;
    }
    h5 {
      font-weight: 600;
      font-size: 20px;
      padding-bottom: 10px;
    }
    p {
      font-size: 15px;
    }
  }
  ${SIZES.mobile} {
    padding: 1em 0;
    background-image: url(${config.assets.registration.portrait});
    .mobile-hide {
      display: none;
    }
    .mobile-show {
      display: block;
    }
    img#banner-portrait {
      display: block;
    }
    #message {
      font-size: 0.75em;
      padding: 1.5rem 1rem;
      width: 85%;
    }
    .col-md-6 {
      flex: none;
      max-width: 100%
    }
  }
`

export default function PostRegisterPage() {

  return (
    <Page>
      <Row noGutters className="h-100">
        <div className="w-100 mobile-show">
          {/* <img width="100%" src={bannerM} alt="event-banner" className="mx-auto my-2" /> */}
        </div>
        <Col md={12} className="align-self-center mt-4 mt-md-0 mobile-hide">
          <div className="brand">
            {/* <img width="100%" src={banner} alt="event-banner" className="d-block ml-0 mr-auto" /> */}
            {/* <img width="60%" src={logo} alt="event-logo" className="d-block mr-0 ml-auto" /> */}
          </div>
        </Col>
        <Col md={12} className="align-self-center position-absolute">
          <div id="message" className="mt-5">
            <h5>
              Thank you for your registration.
            </h5>
            <p>
              As participant is limited to 500 persons, you will receive a confirmation email if your registration is accepted.
            </p>
          </div>
        </Col>
        <div className="mobile-show mb-3 mb-md-5 mt-auto">
          {/* <img width="40%" src={logo} alt="event-logo" className="d-block mx-auto" /> */}
        </div>
      </Row>
    </Page>
  )
}
