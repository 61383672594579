import React from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import { COLORS } from '../../styles/theme'

const StyledModal = styled(Modal)`
  .modal-header {
    border: none;
    padding: 0;
    padding-right: 32px;
    padding-top: 16px;
  }
  .modal-content {
    border: 2px solid ${COLORS.primaryLight};
    border-radius: 36px;
    box-shadow: 0px 4px 12px ${COLORS.lightGray1} ;
  }
  .modal-body {
    padding: 0 32px;
    .content {
      width: 100%;
      overflow-x: scroll;
      padding-bottom: 16px;
      h5 {
        margin: 0;
      }
      p,a {
        margin: 0;
        font-size: 14px;
      }
    }
    .contact {
      width: 100%;
      margin: 16px 0;
    }
  }
`

export default function InfoModal({ infoOpen, setInfoOpen }) {
  return (
    <StyledModal
      show={infoOpen}
      onHide={()=>setInfoOpen(false)}
      size="lg"
      aria-labelledby="info-modal"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="content">
          <div className="mb-3">
            <h5>Taseen Trading Sdn. Bhd.</h5>
            <p>No. 8, KU2, Jln Astana 1F, Bandar Bukit Raja, 41050 Klang, Selangor.</p>
            <a href="tel:+60361433149" className="phone">Tel: +603 3358 1888</a>
          </div>
          <div className="mb-3">
            <h5>Boehringer Ingelheim (Malaysia) Sdn. Bhd.</h5>
            <p>Suite 15-5 Level 15, Wisma Uoa Damansara II, No 6, Changkat Semantan, Bukit Damansara, <br />
              50490 Kuala Lumpur.</p>
            <a href="tel:+60320920088" className="phone">Tel: +603 2092 0088</a><br />
            <a href="fax:+60320952818" className="phone">Fax: +603 2095 2818</a><br />
            <a href="mailto:eric.cheah@boehringer-ingelheim.com" className="phone">Email: Eric.cheah@boehringer-ingelheim.com</a>
          </div>
        </div>
      </Modal.Body>
    </StyledModal>
  )
}





// import React from 'react'
// import { Modal } from 'react-bootstrap'
// import styled from 'styled-components'
// import { COLORS } from '../../styles/theme'
// import whatsapp from '../../images/booth/whatsapp.png'

// const StyledModal = styled(Modal)`
//   .modal-content {
//     border: 2px solid ${COLORS.primaryLight};
//     box-shadow: 0px 4px 12px ${COLORS.lightGray1} ;
//   }
//   .modal-body {
//     padding: 16px 32px;
//     .content {
//       width: 100%;
//       overflow-x: scroll;
//       padding-bottom: 16px;
//     }
//     .divider {
//       background: linear-gradient(270deg, #FE1E9A, #254DDE);
//       width: 100%;
//       height: 4.5px;
//       margin: 16px auto;
//     }
//     .contact {
//       width: 100%;
//       margin: 16px 0;
//       h5 {
//         text-decoration: underline;
//       }
//     }
//   }
// `

// export default function InfoModal({ title, description, content, infoOpen, setInfoOpen, agents }) {
//   return (
//     <StyledModal
//       show={infoOpen}
//       onHide={()=>setInfoOpen(false)}
//       size="lg"
//       aria-labelledby="info-modal"
//       centered
//     >
//       <Modal.Header closeButton>
//         <Modal.Title id="info-modal" className="px-3">{title}</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <div
//           className="content"
//           dangerouslySetInnerHTML={{ __html: content }}
//         />
//         <div className="contact">
//           <div >
//             <h5 className="p-0 font-weight-bold">
//               Contact Informations
//               <img src={whatsapp} width="24px" className="ml-3" alt="whatsapp logo" />
//             </h5>
//           </div>
//           {
//             agents.map((agent,index)=>(
//                 <div key={`Agent-${index+1}`} >
//                   <p className="my-0 font-weight-bold">{agent.name}</p>
//                   <p className="mb-0">{agent.phone}</p>
//                   <p>{agent.email}</p>
//                 </div>
//             ))
//           }
//         </div>
//       </Modal.Body>
//     </StyledModal>
//   )
// }
