import { useObject } from 'react-firebase-hooks/database'
import { useDispatch } from 'react-redux'
import { showGlobalModal } from '../ducks/layout'
import { firebaseDb, getOnlineStatusDb, getUserSessionDb } from '../firebaseConfig'



function useOnlineStatus(currentUser) {
  const dispatch = useDispatch()
  
  const statusRef = getOnlineStatusDb(currentUser.id)
  const userSessionDb = getUserSessionDb(currentUser.id)

  const [snapshot, loading, error] = useObject(firebaseDb.ref(statusRef))
  if (!currentUser.id) return

  if (!loading && !error && snapshot.val() && snapshot.val() === true ) {
    firebaseDb.ref(userSessionDb).limitToLast(1).once("value", snapshot => {
      const data = snapshot.val() || {}

      if (data[Object.keys(data)[0]].end === undefined) {
        // console.log(data[Object.keys(data)[0]].end)
      } else {
        // dispatch(showGlobalModal('refresh'))
      }
    })

  }
}

export { useOnlineStatus }
