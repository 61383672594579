import axios from 'axios'
import config from '../config'

const REQUEST_ENTRIES = 'REQUEST_ENTRIES'
const RECEIVE_ENTRIES = 'RECEIVE_ENTRIES'
const RECEIVE_FINALISTS = 'RECEIVE_FINALISTS'
const RECEIVE_ENTRY = 'RECEIVE_ENTRY'
const SUBMIT_LIKE = 'SUBMIT_LIKE'
const SUBMIT_VOTES = 'SUBMIT_VOTES'

const initialState = {
  entries: [],
  finalists: [],
  entry: null,
  requestLoading: false,
  likeUpdated: false,
  hasVoted: false,
}

export default function entryReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_ENTRIES:
      return {
        ...state,
        entries: null,
        requestLoading: true,
        likeUpdated: false,
      }

    case RECEIVE_ENTRIES:
      return {
        ...state,
        entries: action.entries,
        requestLoading: false
      }
    case RECEIVE_FINALISTS:
      return {
        ...state,
        finalists: action.finalists,
        requestLoading: false
      }

    case RECEIVE_ENTRY:
      return {
        ...state,
        entry: action.entry,
        requestLoading: false
      }
    case SUBMIT_VOTES:
      return {
        ...state,
        hasVoted: action.hasVoted,
        requestLoading: false
      }

    case SUBMIT_LIKE:
      return {
        ...state,
        likeUpdated: true,
        entries: state.entries.map(entry => {
          if (entry.id === action.entry_id) {
            return {
              ...entry, 
              liked: action.liked,
              total_likes: action.liked ? entry.total_likes + 1 : entry.total_likes - 1
            }
          } else return entry
        })
      }

    default:
      return state
  }
}

export function getEntries() {
  return (dispatch) => {
    dispatch(requestEntries())

    return axios
      .get(`${config.apiUrl}/api/v1/entries`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
        },
      })
      .then(({ status, data }) => {
        if (status === 200 && data.success) {
          dispatch(receiveEntries(data.entries.sort((a,b)=> b.total_likes - a.total_likes)))
        }
      })
      .catch((err) => {
        console.error('Error: ', err)
      })
  }
}

export function getFinalists() {
  return (dispatch) => {
    dispatch(requestEntries())

    return axios
      .get(`${config.apiUrl}/api/v1/finalist`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
        },
      })
      .then(({ status, data }) => {
        if (status === 200 && data.success) {
          dispatch(receiveFinalists(data.entries.sort((a,b)=> a.order - b.order)))
          dispatch(votingStatus(data.has_voted))
        }
      })
      .catch((err) => {
        console.error('Error: ', err)
      })
  }
}

export function getEntry(entryId) {
  return (dispatch) => {
    return axios
      .get(`${config.apiUrl}/api/v1/entries/${entryId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
        },
      })
      .then(({ status, data }) => {
        if (status === 200 && data.success) {
          dispatch(receiveEntry(data.entry))
        }
      })
      .catch((err) => {
        console.error('Error: ', err)
      })
  }
}
export function submitVotes(payload) {
  return (dispatch) => {
    dispatch(requestEntries())

    return axios
      .post(`${config.apiUrl}/api/v1/entry_votes`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
        },
      })
      .then(({ status, data }) => {
        if (data.success) {
          console.log(data)
          console.log(payload)
          dispatch(votingStatus(data.success))
        }
      })
      .catch((err) => {
        console.error('Error: ', err)
      })
  }
}

export function submitLike(payload) {
  return (dispatch) => {

    return axios
      .post(`${config.apiUrl}/api/v1/entry_likes`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
        },
      })
      .then(({ status, data }) => {
        if (data.success) {
          dispatch(updateLike(payload.entry_id, data.success))
        }
      })
      .catch((err) => {
        console.error('Error: ', err)
      })
  }
}

function requestEntries() {
  return {
    type: REQUEST_ENTRIES,
  }
}
function receiveEntries(entries) {
  return {
    type: RECEIVE_ENTRIES,
    entries,
  }
}
function receiveFinalists(finalists) {
  return {
    type: RECEIVE_FINALISTS,
    finalists,
  }
}
function receiveEntry(entry) {
  return {
    type: RECEIVE_ENTRY,
    entry,
  }
}
function updateLike(entry_id, liked) {
  return {
    type: SUBMIT_LIKE,
    entry_id,
    liked,
  }
}
function votingStatus(hasVoted) {
  return {
    type: SUBMIT_VOTES,
    hasVoted
  }
}
