import axios from 'axios'
import { toast } from 'react-toastify'
import config from '../config'
import { firebaseAuth } from '../firebaseConfig'
import { handleDeviceSession } from '../utils/sessionHelper'

const LOGIN_REQUEST = 'LOGIN_REQUEST'
const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
const LOGIN_FAILURE = 'LOGIN_FAILURE'
const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'

const initialState = {
  appReady: false,
  isFetching: false,
  isAuthenticated: localStorage.getItem('jwt_token') ? true : false,
  currentUser: {},
}

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
      }

    case LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        currentUser: action.user,
        appReady: true
      }

    case LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
      }

    case LOGOUT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        currentUser: {},
      }
    default:
      return state
  }
}

export function refreshUser(dispatch) {
  return (dispatch) => {
    return axios
      .get(`${config.apiUrl}/api/v1/auto_login`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
        },
      })
      .then(({ status, data }) => {
        if (status === 200 && data.success) {
          dispatch(receiveLoginRefresh(data.user))
        } else {
          dispatch(rejectLogin('Failed to refresh user'))
        }
      })
      .catch((err) => {
        dispatch(rejectLogin('Fail to refresh user (Rejected)'))
        console.error('Error: ', err)
      })
  }
}

export function loginUser(credentials) {
  return async (dispatch) => {
    dispatch(requestLogin())

    try {
      const { status, data } = await axios.post(`${config.apiUrl}/api/v1/login`, credentials)

      if (status === 200 && data.success) {
        const { user: firebaseUser } = await firebaseAuth.signInWithCustomToken(data.firebaseToken)

        // Because we are not setting up a dev env firebase db
        // we do not want to set user's email in Firebase if the log in
        // is happening on development environment to prevent email unique constraint error
        if (process.env.NODE_ENV === 'production') {
          firebaseUser.updateEmail(data.user.email)
        }
        handleDeviceSession(data.user.id)
        localStorage.setItem('jwt_token', data.token)
        dispatch(receiveLogin(data.user))
        // console.log('Firebase: ', firebaseUser.email)
      } else {
        dispatch(rejectLogin(data.error))
      }
    } catch (error) {
      dispatch(rejectLogin(error.response?.data?.message))
      console.error('Error: ', error)
    }
  }
}

const clearUserSession = () => {
  localStorage.removeItem('jwt_token')
  localStorage.removeItem('session_token')
  localStorage.removeItem('open_tab')
}

export function logoutUser() {
  firebaseAuth
    .signOut()
    .then(() => {
      toast('You are logged out!', {
        toastId: 'logoutToast',
      })
    })
    .catch((error) => {
      console.error(error)
    })
  return (dispatch) => {
    clearUserSession()
    dispatch(receiveLogout())
  }
}

export function kickUser() {
  toast('You have logged in from another browser!', {
    toastId: 'logoutToast',
  })
  return (dispatch) => {
    clearUserSession()
    dispatch(receiveLogout())
  }
}

function requestLogin() {
  return {
    type: LOGIN_REQUEST,
  }
}

function receiveLoginRefresh(user) {
  return {
    type: LOGIN_SUCCESS,
    user,
  }
}

function receiveLogin(user) {
  toast(`Hello ${user.fullName}, welcome to ${config.eventName}!`)
  return {
    type: LOGIN_SUCCESS,
    user,
  }
}

function rejectLogin(message) {
  if (message) {
    toast(message)
  } else {
    toast('Something went wrong, please try again later.')
  }
  return {
    type: LOGIN_FAILURE,
  }
}

function receiveLogout() {
  return {
    type: LOGOUT_SUCCESS,
  }
}
