import React, { useState } from 'react'
import styled from 'styled-components'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { COLORS, SIZES } from '../../styles/theme'
import Axios from 'axios'
import config from '../../config'
import { toast } from 'react-toastify'
import routes from '../../routes'
import PhoneInput from 'react-phone-input-2'

const Page = styled.div`
  padding: 0;
  color: #000000;
  background-color: #06063a;
  min-height: 100vh;

  .bg-image {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    min-height: 100vh;
  }
  #main {
    position: absolute;
    top: 28%;
  }
  .mobile-show {
    display: none;
  }
  .brand {
    width: 80%;
    height: 100%;
    padding-bottom: 3em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .row {
    select.form-input {
      text-align-last: center;
    }
   
    h6.subtitle {
      font-weight: 500;
      margin: 24px 0;
    }
    .error-message {
      font-weight: 500;
      color: #C10244;
      text-align: center;
      margin: 0;
      margin-top: 8px;
    }
   
    .register-form {
      padding: 2.5em 0;

      .form-group {
        width: 100%;
        margin: 8px auto;
        box-shadow: 0 5px 4px -4px #000000;
        background: #FFFFFFD6;
        border: none;
        font-weight: 500;
        text-align: center;
        label {
          color: #000000;
          padding: 0 8px;
          margin: 0 auto;
          padding-right: 0;
          font-size: 13px;
        }
        .required:before {
          content:"* ";
          color: red;
        }
        input[type='tel'],
        .form-input {
          display: block;
          color: #484848;
          width: 100%;
          font-weight: 500;
          background: transparent;
          border: none;
          padding: 4px 24px;
          outline: none;
          text-align: center;
          &:focus {
            box-shadow: none;
          }
        }
        .flag-dropdown, .selected-flag {
          background: transparent;
          border: none;
        }
        ul.country-list {
          margin: 0;
          font-style: normal;
          background: rgba(255, 255, 255, 0.85);
        }
      }
      #password {
        width: 12px;
        margin: 2px 4px;
      }
    }
  }
  .form-check {
    display:flex;
    align-items: flex-start;
    padding: 0;
    .agree-box {
      padding: 8px;
    }
    .form-check-label {
      color: white;
      font-size: 12px;
      margin-left: 8px;
      a {
        text-decoration: underline;
        font-weight: 500;
      }
    }
  }
  ${SIZES.mobile} {
    min-height: 100vh;
    background-image: url(${config.assets.registration.portrait});
    background-size: contain;
    background-position: center top;
    background-repeat: no-repeat;
    padding: 0;
    padding-bottom: 2.5em;
    padding-top: 2.5em;
    font-size: 0.9em;

    #main {
      margin-top: 8rem;
      position: static;
    }
    .bg-image {
      display: none;
    }
    .mobile-show {
      display: block;
    }
    .mobile-hide {
      display: none;
    }
    .col-md-6 {
      flex: none;
      max-width: 100%
    }
    .subtitle, .error-message {
      padding: 0 20px;
      font-size: 12px;
      font-weight: 600;
    }
  }
  
  button.submit-btn {
    background: #68C9F2;
    color: #134068;
    box-shadow: 0 5px 4px -4px #000000;
    width: 40%;
    font-weight: 600;
    border: none;
    border-radius: 0;
    display: flex;
    justify-content: center;
    padding: 12px 24px;
    margin: 16px auto;
    margin-bottom: 0;
    font-size: 1em;
    outline: none;

    ${SIZES.mobile} {
      width: 60%;
      padding: 10px 24px;
    }

    &:disabled {
      cursor: not-allowed;
      background: grey;
      opacity: 1;
    }

    div {
      line-height: 32px;
    }
  }
`

export default function RegisterFormPage() {
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [fullName, setFullName] = useState('')
  const [phone, setPhone] = useState("+60")
  const [company, setCompany] = useState('')
  const [designation, setDesignation] = useState('')
  const [address, setAddress] = useState("")
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [agree1, setAgree1] = useState(false)
  const [agree2, setAgree2] = useState(false)
  const [agree3, setAgree3] = useState(false)
 
  const handleCheckBox = (e) => {
    const { checked, name } = e.target
    if (name === 'agree1'){
      setAgree1(checked)
    }
    if (name === 'agree2'){
      setAgree2(checked)
    }
    if (name === 'agree3'){
      setAgree3(checked)
    }
  }

  const handleEmailInput = (e) => {
    setEmail(e.target.value.toLowerCase())
  }
  
  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    const options = {
      full_name: fullName.trim(),
      phone,
      password: password.trim(),
      email,
      company: company.trim(),
      designation: designation.trim(),
      address: address.trim(),
    }
    Axios.post(`${config.apiUrl}/api/v1/register`, options)
      .then(() => {
        setLoading(false)
        history.push(routes.postRegister)
      })
      .catch((err) => {
        setLoading(false)
        toast(err.response.data.message)
      })
  }

  const hasEmptyField = !(
    fullName.length &&
    phone.length >= 7 &&
    password.length &&
    company.length &&
    designation.length &&
    address.length &&
    agree1 &&
    agree2 &&
    agree3 &&
    email.length
    // password.length >= 8 
  )

  return (
    <Page>
      <img src={config.assets.registration.landscape} className="bg-image mobile-hide" alt="bg-image" width="100%" />
      <Row id="main" noGutters className="pl-md-0 pt-2 pb-0">
        <Col md={12} className="px-md-4">
          <Row className="register-form py-3 mt-5 w-75 mx-auto" noGutters>
            <Col md={6} className="px-md-4">
              <Form.Group>
                <label className="required">Full Name :</label>
                <input
                  className="form-input"
                  required
                  type="text"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="px-md-4">
              <Form.Group>
                <label className="required">Contact :</label>
                <PhoneInput
                  inputProps={{ required: true }}
                  className="form-input"
                  value={phone}
                  onChange={(phone) => setPhone(phone)}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="px-md-4">
              <Form.Group>
                <label className="required">Email Address :</label>
                <input
                  className="form-input"
                  required
                  type="email"
                  value={email}
                  onChange={handleEmailInput}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="px-md-4">
              <Form.Group>
                <label className="required">
                  Password :
                </label>
                <input
                  className="form-input"
                  required
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="px-md-4">
              <Form.Group>
                  <label className="required">Company / Place of Practice :</label>
                  <input
                    className="form-input"
                    required
                    type="text"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                  />
              </Form.Group>
            </Col>
            <Col md={6} className="px-md-4">
              <Form.Group>
                <label className="required">Designation :</label>
                <input
                  className="form-input"
                  required
                  type="text"
                  value={designation}
                  onChange={(e) => setDesignation(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={12} className="px-md-4">
              <Form.Group>
                <label className="required">Address To Received Token :</label>
                <input
                  className="form-input"
                  required
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={12} className="mt-md-2 px-0 px-md-4 text-center">
              <div className="form-check">
                <input 
                  type="checkbox"
                  name="agree1" 
                  className="agree-box" 
                  onChange={handleCheckBox}
                />
                <label className="form-check-label text-left">
                  <span className="text-danger">*</span>&nbsp;
                  I hereby confirm that I have read and understood the information notices of Apxara’s&nbsp;
                  <a href="https://drive.google.com/file/d/1svA1-V8fRibT61CjwhzFkzJVUQR4A6Fc/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                    Terms & Conditions and Privacy Notice
                  </a>
                  , and consent to the processing of my personal data for the purpose of my registration to the website/platform/apps in accordance with laws and regulations on Data Privacy and Data Protection.
                </label>
              </div>
              <div className="form-check">
                <input 
                  type="checkbox"
                  name="agree2" 
                  className="agree-box" 
                  onChange={handleCheckBox}
                />
                <label className="form-check-label text-left">
                  <span className="text-danger">*</span>&nbsp;
                  I hereby consent and agree that Apxara may collect, process and communicate my personal data for purposes as described above.
                  </label>
              </div>
              <div className="form-check">
                <input 
                  type="checkbox"
                  name="agree3" 
                  className="agree-box" 
                  onChange={handleCheckBox}
                />
                <label className="form-check-label text-left">
                  <span className="text-danger">*</span>&nbsp;
                  I hereby consent and agree that Apxara may transfer/share my personal data with Boehringer Ingelheim Malaysia and Taseen Trading Sdn Bhd as the co-organisers for the above event.
                  </label>
              </div>
            </Col>
            <div className="w-100 px-md-4">
              {hasEmptyField && (
                <p className="error-message">
                  * Please fill in the required field to proceed.
                </p>
              )}
              <Button
                type="submit"
                className="submit-btn"
                variant="default"
                disabled={hasEmptyField || loading}
                onClick={handleSubmit}
              >
                <div>{loading ? 'Loading...' : 'SUBMIT'}</div>
              </Button>
            </div>
          </Row>
        </Col>
      </Row>
      <div className="mobile-show">
        {/* <img width="40%" src={logo} alt="event-logo" className="d-block mx-auto" /> */}
      </div>
    </Page>
  )
}
