import React, { useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import agendaImage from '../../images/event/agenda.jpg'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { COLORS } from '../../styles/theme'

const StyledModal = styled(Modal)`
  text-align: center;
  color: ${COLORS.primary};

  .modal-dialog {
    min-width: 75%;
  }
  .modal-content {
    background-color: rgba(255, 255, 255, 0.9);
    border: none;
    border-radius: 0;
    padding: 24px 36px;
    margin-bottom: 24px;

    .modal-header {
      color: #FFAD2C;
      border: none;
      text-align: left;
      padding-top: 0px;
      padding-bottom: 0px;
      button {
        font-size: 36px;
        opacity: 1;
        color: rgba(0, 0, 0, 0.55);
      }
    }
    .navigation {
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: flex-end;
      button {
        width: 150px;
        background: #DFE6EE;
        color: #3C1E5D;
        border: 2px solid #3C1E5D;
        border-radius: 12px;
        padding: 6px 16px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        h5 {
          margin: 0;
        }
        &:hover {
          background: #3C1E5D;
          color: #DFE6EE;
        }
      }
    }
    .divider {
      background: #3C1E5D;
      height: 4.5px;
      margin: 32px 0;
    }
  }
  .back-top {
    border: none;
    border-radius: 12px;
    background: #3C1E5D;
    position:absolute;
    bottom: 0;
    right: 0;
    svg {
      width:50;
      height:50;
    }
  }
  .modal-body {
    padding-top: 0px;
    padding-bottom: 36px;
  }
  .agenda {
    button {
      margin: 16px;
      background: #002060;
      border: 2px solid #FFAD2C;
      font-weight: 500;
      border-radius: 32px;
      padding: 4px 12px;
      color: white;
      font-size: 24px;
    }
    .active {
      font-style: italic;
      color: #FFAD2C;
      text-decoration: underline;
    }
  }
`

export default function ProgrammeModal({ showModal, hideSchedule, currentUser }) {
  const startModal = useRef()

  const scrollTop = (ref) =>{
    ref.current.scrollIntoView({ behavior: 'smooth' })
  };
  
  
  
  return (
    <StyledModal
      show={showModal}
      onHide={hideSchedule}
      size="xl"
      aria-labelledby="menu-modal"
      centered
    >
      <Modal.Header closeButton>
        {/* <Modal.Title>MENU</Modal.Title> */}
        <span ref={startModal}></span>
      </Modal.Header>
      <Modal.Body>
        <LazyLoadImage effect="black-and-white" src={agendaImage} width="100%" alt="event agenda" />
        {/* <div className="navigation">
          <button onClick={()=>scrollTop(agendaSec)}>
            <h5>Agenda</h5>
          </button>
          <button onClick={()=>scrollTop(speakerSec)}>
            <h5>Speakers</h5>
          </button>
        </div>
        <div className="divider"></div>
        <span ref={agendaSec}></span>
        <div>
          <LazyLoadImage effect="black-and-white" src={agendaImage} width="100%" alt="event agenda" />
        </div>
        <div className="divider"></div>
          <div ref={speakerSec}></div>
          <LazyLoadImage effect="black-and-white" src={speaker} width="100%" alt="event speaker" /> */}
        <button onClick={()=>scrollTop(startModal)} className="back-top">
          <svg width="45" height="45" viewBox="0 0 45 45">
            <g transform="translate(-1767 -3747)">
              <g transform="translate(120 -2428)">
                <path d="M22.175,9.358a1.43,1.43,0,0,0-1.43-1.43H5.063l5.813-5.42A1.43,1.43,0,1,0,8.923.421L.455,8.311a1.43,1.43,0,0,0,0,2.091L8.923,18.3A1.43,1.43,0,1,0,10.875,16.2L5.063,10.788H20.746A1.43,1.43,0,0,0,22.175,9.358Z" transform="translate(1678.681 6187) rotate(90)" fill="#DFE6EE"/>
              </g>
            </g>
          </svg>
        </button>
      </Modal.Body>
    </StyledModal>
  )
}
