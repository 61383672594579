import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import cloudflixMats from '../constants/cloudflixVids'
import Amplitude from '../utils/amplitudeHelper'
import boothImg from '../images/booth/booth.png'
import config from '../config'
import BoothMaterial from '../components/Pointer'
import ModalVideo from 'react-modal-video'
import InfoModal from '../components/GlobalModals/InfoModal'
import PageWithBg from '../components/PageWithBg'
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component'

const Page = styled.div`
  min-height: 100vh;

  .bg-img {
    position: relative;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  .booth-container {
    position: fixed;
    left: 50%;
    width: 100vw;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -75%);
  }
`

export default function CloudflixPage({ currentUser }) {
  const [isOpen, setOpen] = useState(false)
  const [video, setVideo] = useState('')
  const [infoOpen, setInfoOpen] = useState(false)
  const [showBtn, setShowBtn] = useState(false)
  
  useEffect(() => {
    Amplitude.visit("Lounge")
  }, [currentUser.id])
  
  const handleModal = (videoId) => {
    Amplitude.clickHotButtons('video')
    setOpen(true)
    setVideo(videoId)
  }
  const handleInfo = () => {
    Amplitude.clickHotButtons('info')
    setInfoOpen(true)
  }
 
  return (
    <Page>
      <PageWithBg bgImg={config.assets.booth.landscape}>
        <LazyLoadComponent>
            <div className="booth-container">
              <LazyLoadImage
                effect="black-and-white"
                afterLoad={() => setShowBtn(true)}
                className="booth-img"
                src={boothImg}
                width="100%"
                alt="booth image"
              />
              { showBtn &&
                cloudflixMats.map(mat => (
                  <BoothMaterial 
                    key={mat.id}
                    toggleVideoModal={handleModal}
                    toggleInfo={handleInfo}
                    {...mat}
                  />
                ))
              }
            </div>
        </LazyLoadComponent>
      </PageWithBg>
      <ModalVideo channel='vimeo' autoplay isOpen={isOpen} videoId={video} onClose={() => setOpen(false)} />
      <InfoModal infoOpen={infoOpen} setInfoOpen={setInfoOpen} />
    </Page>
  )
}