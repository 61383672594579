import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import BgImg from '../../images/backdrops/posterPlainBg.png'
import config from '../../config'
import Amplitude from '../../utils/amplitudeHelper'
import logos from '../../images/backdrops/voting-logo.png'
import header from '../../images/backdrops/voting-guide.png'
import voteBtn from '../../images/backdrops/vote-btn.png'
import down from '../../images/icons/arrow-down.png'
import routes from '../../routes'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { useObject } from 'react-firebase-hooks/database'
import { useDispatch, useSelector } from 'react-redux'
import { getFinalists } from '../../ducks/entry'
import { VotingAccessDb } from '../../firebaseConfig'
import { EntryVote } from '../../components/EntryVote'
import LoadIcon from '../../components/LoadIcon'
import PreviewModal from '../../components/PreviewModal'

const MobileVoting = ({ currentUser }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { finalists, requestLoading } = useSelector((state) => state.entry)
  const [snapshot, loading, error] = useObject(VotingAccessDb)
  const [showFinalists, setShowFinalists] = useState(false)
  const [showPreview, setShowPreview] = useState(false)
  const [previewEntry, setPreviewEntry] = useState(null)

  useEffect(() => {
    document.title = `${config.eventName} | Best Photo`
  }, [])

  useEffect(() => {
    if (!loading) {
      if (!snapshot.val() && currentUser.role === 'participant') {
        history.push(routes.lobby)
        toast('Best Photo Voting will be available soon.')
      } else {
        Amplitude.visit('Best Photo')
      }
    }
  }, [loading, snapshot, history, currentUser.id])

  useEffect(() => {
    dispatch(getFinalists())
  }, [dispatch])

  const handleExpandFinalist = () => {
    setShowFinalists(!showFinalists)
  }

  const previewPhoto = (entry) => {
    setPreviewEntry(entry)
    setShowPreview(true)
  }
  const closePreview = () => {
    setShowPreview(false)
    setPreviewEntry(null)
  }

  if (requestLoading) {
    return <LoadIcon />
  }
  return (
    <Page>
      <div className="outer-wrapper">
        <div className="container">
          <img className='my-1' width="100%" src={logos} alt="page-header"/>
          <img width="90%" src={header} alt="page-header"/>
          <div className='mb-5' onClick={handleExpandFinalist}>
            <img src={down} width="10%" alt="arrow down" className={`${showFinalists ? 'up' : 'down'}`} />
          </div>
          
            {showFinalists && finalists.map(entry=>(
              <div className="mobile-preview" key={`mobile-entry-${entry.id}`}>
                <div className="photo-box">
                  <div onClick={()=>previewPhoto(entry)} className="entry-photo" style={{backgroundImage: `url(${entry.photo.url})`}}>
                    <EntryVote entry={entry} />
                  </div>
                  <div className="entry-details">
                    <p className="entry-title">{entry.photo_title}</p>
                    <p className="entry-caption">{entry.caption}</p>
                  </div>
                </div>
                <div className="participant-details">
                  <h6 className="entry-order">{entry.order}</h6>
                  <span className="entry-participant">{entry.participant.fullName}</span>
                </div>
              </div>
            ))}
          <div className='mt-2 mb-5' onClick={()=>history.push(routes.votingform)}>
            <img src={voteBtn} className="vote-btn" width="65%" alt="vote button" />
          </div>
        </div>
      </div>
      {showPreview && <PreviewModal entry={previewEntry} showModal={showPreview} closeModal={closePreview}/>}
    </Page>
  )
}

const Page = styled.div`
  background: url(${BgImg});
  background-size: cover;
  background-position: top center;

  height: calc(100% - 60px);
  width: 100vw;
  border-radius: 0px 0px 25px 25px;
  position: absolute;
  overflow-y: scroll;


  .outer-wrapper {
    width: 100%;
  }
  
  .container {
    width: 90%;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 36px;
  }
  .up {
    transform: scaleY(-1);
  }
  .mobile-preview {
    width: 90%;
    margin: 0px auto;
    margin-bottom: 18px;
  }

  .photo-box {
    height: 57vh;
    min-height: 350px;
    background-color: #fff;
    margin: 0px auto;
    padding-top: 4px;
    box-shadow: 2px 5px 6px -2px rgba(255, 255, 255, 0.75);
    .entry-photo {
      cursor: pointer;
      position: relative;
      background-position: top center;
      background-size: cover;
      height: 70%;
      margin: 0px 4px;
      opacity: 0;
      animation: appear 1s forwards;
    }
    .entry-details {
      padding: 0 4px;
      text-align: center;
      .entry-title {
        margin: 0;
        font-weight: 500;
        color: #060738;
        margin: 4px 0;
        font-size: 1em;
      }
      .entry-caption {
        color: rgba(0, 0, 0, 0.5);
        font-size: 0.85em;
      }
    }
  }
  .participant-details {
    padding: 4px;
    display: flex;
    text-align: left;
  }
  .entry-order {
    font-size: 38px;
    font-weight: 600;
    color: #9AEEBC;
    margin-right: 8px;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.2);
  }
  .entry-participant {
    font-weight: 500;
    color: white;
    text-transform: uppercase;
    font-size: 18px;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.2);
  }

  @media screen and (min-width: 540px) {
    .outer-wrapper {
      width: 600px;
      margin: auto;
    }
    .photo-box > .entry-details {
      .entry-title {
        font-size: 1.6em;
      }
      .entry-caption {
        font-size: 1.3em;
      }
    }
    .entry-order {
      font-size: 42px;
    }
    .entry-participant {
      font-size: 24px;
    }
  }
`

export default MobileVoting
