import React from 'react'
import styled from 'styled-components'
import { Switch, Route } from 'react-router-dom'

import LoginPage from '../pages/LoginPage'
import WelcomePage from '../pages/WelcomePage'
import config from '../config'
import routes from '../routes'
import { SIZES } from '../styles/theme'

const Page = styled.div`
  background: url(${config.assets.entrance.landscape}) no-repeat center center
    fixed;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  #title {
    position: fixed;
    top: 15%;
    right: 100px;
    background: rgba(255, 255, 255, 0.7);
    width: 400px;
    padding: 36px 24px;
    border-radius: 12px;
    h4 {
      color: #002060;
      text-align: center;
      margin: 0;
    }
    ${SIZES.mobile} {
      max-width: 90%;
      top: 10%;
      right: 50%;
      transform: translateX(50%);
      
    }
  }
`

export default function EntrancePages({ children }) {
  return (
    <Page>
      <header id="title">
        <h4>Welcome to<br /> BIAH Taseen MY Poultry Platform</h4>
      </header>
      <Switch>
        <Route exact path={routes.root} component={WelcomePage} />
        <Route path={routes.login} component={LoginPage} />
      </Switch>
    </Page>
  )
}
