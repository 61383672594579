import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Link, useHistory } from 'react-router-dom'
import BgImg from '../../images/backdrops/posterPlainBg.png'
import routes from '../../routes'
import config from '../../config'
import Amplitude from '../../utils/amplitudeHelper'
import rules from '../../images/backdrops/rules.png'
import logos from '../../images/backdrops/poster-logos.png'
import poster from '../../images/backdrops/posterM-1.png'
import header from '../../images/backdrops/poster-header.png'
import { toast } from 'react-toastify'

const MobileContest = ({ currentUser }) => {
  const history = useHistory()

  useEffect(() => {
    history.push(routes.lobby)
    toast("Photo Contest is now closed. Photo Voting will be available soon.")
    // Amplitude.visit('Photo Contest')
  }, [currentUser.id])

  useEffect(() => {
    document.title = `${config.eventName} | Photo Contest`
  }, [])

  return (
    <Page>
      <div className="outer-wrapper">
        <div className="container">
          <img className='my-4' width="100%" src={logos} alt="page-header"/>
          <img className='mb-5' width="100%" src={header} alt="page-header"/>
          <Link id="entry-btn" to={routes.photoform}>Submit Your Entry</Link>
          <img className='my-2' width="100%" src={poster} alt="poster"/>
          <img className='my-5' width="100%" src={rules} alt="rules"/>
        </div>
      </div>
    </Page>
  )
}

const Page = styled.div`
  background: url(${BgImg});
  background-size: cover;
  background-position: top center;

  height: calc(100% - 60px);
  width: 100vw;
  border-radius: 0px 0px 25px 25px;
  position: absolute;
  overflow-y: scroll;


  .outer-wrapper {
    width: 100%;
  }

  .container {
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }
  #entry-btn {
    background: linear-gradient(#A8FCCA, #01D0B7);
    border-radius: 36px;
    padding: 8px 36px;
    color: black;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    border: none;
    margin: 12px auto;
  }

  @media screen and (min-width: 540px) {
    .outer-wrapper {
      width: 600px;
      margin: auto;
    }
  }
`

export default MobileContest
