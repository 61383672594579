import React from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

const CustomModal = styled(Modal)`
  .modal-content {
    border-radius: 0px;
    background-color: rgba(255,255,255,0.9);
    padding: 0 16px;
    color: #05093A;
  }
  .modal-header {
    border: none;
    padding: 0 16px;
  }
`

export default function AlertModal({ showModal, hideSchedule }) {
  return (
    <CustomModal
      show={showModal}
      onHide={hideSchedule}
      size="md"
      aria-labelledby="message"
      centered
    >
      <Modal.Body>
        <h5 className="py-5 px-1 px-md-4 text-center">
          Something exciting is coming soon!<br /> Stay Tuned.
        </h5>
      </Modal.Body>
    </CustomModal>
  )
}
