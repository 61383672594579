import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import LobbyEntry from '../components/LobbyEntry'
import LobbyOverlay from '../components/LobbyOverlay'
import PageWithBg from '../components/PageWithBg'
import config from '../config'
import Amplitude from '../utils/amplitudeHelper'
// import gif from '../images/gifs/kv.gif'
import gif from '../images/backdrops/banner.jpg'
import { toast } from 'react-toastify'

const PageWrapper = styled.div`
  .trailer {
    background-color: #000001;
    position: absolute;
    top: 24%;
    left: 38.1%;
    right: 38.5%;
    bottom: 51.9%;
  }
`

export default function LobbyPage({ currentUser }) {
  const [overlayTitle, setOverlayTitle] = useState('Select hall to enter:')
  const [overlay, setOverlay] = useState(null)

  const showEntryOverlay = (destinations) => {
    // setOverlay(destinations)
    toast("Coming Soon...")
  }

  const hideOverlay = () => setOverlay(null)

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      hideOverlay()
    }
  }, [])

  const showHelpDesk = () => {
    const cpt = window.chaport
    cpt.on('ready', function () {
      cpt.setVisitorData({
        name: currentUser.fullName,
        email: currentUser.email,
      })
      cpt.open()
    })
    cpt.on('collapsed', function () {
      cpt.close()
    })
    Amplitude.clickHelpDesk()
  }

  useEffect(() => {
    document.title = `${config.eventName} | Lobby`
  }, [])

  useEffect(() => {
    Amplitude.visit('Lobby')
  }, [currentUser.id])

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [escFunction])

  return (
    <PageWrapper>
      <PageWithBg bgImg={config.assets.lobby.landscape}>
        <LobbyOverlay overlayTitle={overlayTitle} overlay={overlay} hideOverlay={hideOverlay} />
        {config.lobbyEntries.map((entry) => {
          return <LobbyEntry showHelpDesk={showHelpDesk} key={entry.id} setOverlayTitle={setOverlayTitle} showEntryOverlay={showEntryOverlay} {...entry} />
        })}
        <div className="trailer">
          <img width="100%" src={gif} alt="key visual" />
        </div>
      </PageWithBg>
    </PageWrapper>
  )
}
