import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Amplitude from '../../utils/amplitudeHelper'
import config from '../../config'
import routes from '../../routes'
import play from '../../images/mobile/play.png'
import pdf from '../../images/mobile/pdf.png'
import info from '../../images/mobile/info.png'
import LoadIcon from '../../components/LoadIcon.js'
import boothImg from '../../images/booth/booth.png'

const MobileExpoBooth = ({ currentUser }) => {
  const [videoLoading, setVideoLoading] = useState(true)
  const [pdfModal, setPdfModal] = useState(false)
  const [infoModal, setInfoModal] = useState(false)
  const [iframeModal, setIframeModal] = useState(false)
  const [videoLink, setVideoLink] = useState(null)
  const documents = [
    {
      filename: "Bda Blen AVA.pptx ver1.pdf",
      url: "https://drive.google.com/file/d/1GvY6mtikiVK0OKQc5hUGN5BRgx1-h1Rs/view?usp=sharing",
    },
    {
      filename: "Bda Blen Mixing and Handling Procedures",
      url: "https://drive.google.com/file/d/1XsTKtCzw7KIDFDwwggeoltJjl2mNL3Yy/view?usp=sharing",
    },
    {
      filename: "Injectable Vaccines Mixing and Handling Procedures",
      url: "https://drive.google.com/file/d/1RzOkQ9KOQax1YoiWVZfKHRGcc4ZjWVm_/view?usp=sharing",
    },
    {
      filename: "NEWXXITEK HVT+ ND",
      url: "https://drive.google.com/file/d/1aEZ8WLLTOepQdDnFZdNlbb4Ik5Kw5Pk3/view?usp=sharing",
    },
  ]

  useEffect(() => {
    Amplitude.visit("Lounge")
  }, [currentUser.id])


  const togglePdfModal = () => setPdfModal(!pdfModal)

  const toggleInfoModal = () => {
    if (!infoModal){
      Amplitude.clickHotButtons('info')
    }
    setInfoModal(!infoModal)
  }
  const toggleIframeModal = () => {
    setVideoLink(null)
    setVideoLoading(true)
    setIframeModal(!iframeModal)
  }

  const handleVideoClick = () => {
    Amplitude.clickHotButtons('video')
    setVideoLink("https://player.vimeo.com/video/535769850?autoplay=1")
    setIframeModal(true)
  }

  const handlePdfClick = () => {
    setPdfModal(true)
  }
  const trackPdf = (link) => {
    Amplitude.clickHotButtons('pdf')
    window.open(
      link,
      '_blank'
    )
  }
  
  return (
    <Page>
      <div className="outer-wrapper">
        {iframeModal && (
          <div className="modal-container">
            <p className="modal-container-close" onClick={toggleIframeModal}>
              Close
            </p>
            <div className="modal-container-content">
              <div className="iframe-container">
                {videoLoading && <LoadIcon />}
                {videoLink && (
                  <iframe
                    title={videoLink}
                    src={videoLink}
                    allow="autoplay; fullscreen; encrypted-media"
                    allowFullScreen
                    allowtransparency="true"
                    frameBorder="0"
                    onLoad={() => {
                      setVideoLoading(false)
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        )}

        {pdfModal && (
          <div className="modal-container">
            <p className="modal-container-close" onClick={togglePdfModal}>
              Close
            </p>
            <div className="modal-container-content">
              <p className="modal-content-title">Select file to download</p>
              {documents.map((item, index) => (
                <div className="modal-content-row" key={index}>
                  <p className="modal-content-label">{item.filename}</p>
                  <div
                    onClick={() => trackPdf(item.url)}
                    className="modal-content-button"
                  >
                    View
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {infoModal && (
          <div className="modal-container">
            <p className="modal-container-close" onClick={toggleInfoModal}>
              Close
            </p>
            <div className="modal-container-content">
              <div className="my-2">
                <h5>Taseen Trading Sdn. Bhd.</h5>
                <p>No. 8, KU2, Jln Astana 1F, Bandar Bukit Raja, 41050 Klang, Selangor.</p>
                <a href="tel:+60361433149" className="phone">Tel: +603 3358 1888</a>
              </div>
              <div className="my-2">
                <h5>Boehringer Ingelheim (Malaysia) Sdn. Bhd.</h5>
                <p className="m-0">Suite 15-5 Level 15, Wisma Uoa Damansara II, No 6, Changkat Semantan, Bukit Damansara, 50490 Kuala Lumpur.</p>
                <a href="tel:+60320920088" className="phone">Tel: +603 2092 0088</a><br />
                <a href="fax:+60320952818" className="phone">Fax: +603 2095 2818</a><br />
                <a href="mailto:eric.cheah@boehringer-ingelheim.com" className="phone">Email: Eric.cheah@boehringer-ingelheim.com</a>
              </div>
            </div>
          </div>
        )}

        <div className="container">
          <div className="hall-info">
            <Link to={routes.lobby}>Lounge > BI Animal Health Best Match Celebration</Link>
          </div>
        </div>

        <div className="container">
          <div className="nav-wrapper"></div>
        </div>

        <div className="booth-image-wrapper">
          <img src={boothImg} className="booth-image" alt="booth"/>
        </div>

        <div className="container p-0">
          <div className="booth-menu-list">
            <div className="booth-menu-item" onClick={handleVideoClick}>
              <img src={play} className="booth-menu-item-icon" alt="video-icon"/>
              <p className="booth-menu-item-label">Video Gallery</p>
            </div>

            <div className="booth-menu-item" onClick={handlePdfClick}>
              <img src={pdf} className="booth-menu-item-icon" alt="resource-icon"/>
              <p className="booth-menu-item-label">PDF Gallery</p>
            </div>

            <div className="booth-menu-item" onClick={toggleInfoModal}>
              <img src={info} className="booth-menu-item-icon" alt="info-icon"/>
              <p className="booth-menu-item-label">Booth Info</p>
            </div>

          </div>
        </div>
      </div>
    </Page>
  )
}

const Page = styled.div`
  background: white;
  height: calc(100% - 60px);
  width: 100vw;
  overflow-y: scroll;
  border-radius: 0px 0px 25px 25px;
  position: absolute;

  .container {
    width: 90%;
    margin: auto;
  }

  .hall-info {
    font-size: 12px;
    margin: 20px 0px 20px 0px;
    color: black;

    a {
      color: black;
    }
  }

  .nav-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    /*background: orange;*/
    margin-bottom: 20px;
  }

  .booth-name {
    width: 70%;
    font-size: 16px;
    margin: 0;
  }

  .nav-buttons-wrapper {
    width: 50px;
    height: 20px;
    /*background: green;*/
    display: flex;
    justify-content: space-between;
  }

  .nav-button-icon {
    height: 20px;
  }

  .booth-image-wrapper {
    background-image: url(${config.assets.booth.landscape});
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 200px;
  }

  .booth-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .booth-menu-list {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px 20px 0px;
  }

  .booth-menu-item {
    height: 60px;
    width: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .booth-menu-item-icon {
    height: 25px;
  }

  .booth-menu-item-label {
    font-size: 10px;
    color: #492379;
    margin: 0;
    text-align: center;
  }

  .booth-info-wrapper {
    padding: 20px 0px 20px 0px;
    font-size: 12px;
    border-top: solid 3px #c69dff;
    border-bottom: solid 3px #c69dff;
    margin-bottom: 20px;
    width: 100%;
    overflow-x: scroll;

    p {
      margin-bottom: 0;
      width: 100%;
    }
  }

  .booth-contact-info-title {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .booth-contact-item-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .booth-contact-item-info {
    color: #8669b1;
    font-size: 14px;

    p {
      margin-bottom: 0;
    }
  }

  .booth-contact-item-icon {
    height: 25px;
  }

  .load-container {
    width: 100%;
    height: 100px;
    position: relative;
  }

  .modal-container {
    width: 95%;
    height: 60%;
    border-radius: 30px;
    border: solid 2px #5c628a;
    position: fixed;
    bottom: 1%;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    z-index: 99;
  }

  .modal-container-close {
    font-size: 12px;
    color: #312384;
    font-weight: bold;
    margin-bottom: 0;
    position: absolute;
    top: 20px;
    right: 30px;
  }

  .modal-container-content {
    width: calc(100% - 60px);
    height: calc(100% - 70px);
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    overflow-y: scroll;
    font-size: 12px;
  }

  .modal-content-title {
    font-size: 16px;
    font-style: italic;
    color: #5461a2;
  }

  .modal-content-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .modal-content-label {
    width: 70%;
    margin-bottom: 0;
    margin: 0;
    font-size: 14px;
  }

  .modal-content-button {
    background: #5461a2;
    color: white;
    font-size: 10px;
    border: none;
    border-radius: 20px;
    padding: 5px 20px;
  }

  .modal-content-text {
    font-size: 12px;
  }

  #testtesttest {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .tawk-modal-container {
    width: 100%;
    height: 80%;
    border-radius: 30px;
    border: solid 2px #5c628a;
    position: fixed;
    bottom: 1%;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    z-index: 99;
  }

  .tawk-modal-container-content {
    width: 100%;
    height: calc(100% - 70px);
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    overflow-y: scroll;
    font-size: 12px;
  }

  .iframe-container {
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
    background: black;
    position: absolute;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (min-width: 540px) {
    .outer-wrapper {
      width: 540px;
      margin: auto;
    }

    .booth-image-wrapper {
      height: 300px;
    }

    .modal-container {
      max-width: calc(0.95 * 540px);
    }

    .tawk-modal-container {
      max-width: 540px;
    }
  }
`

export default MobileExpoBooth
