import LogoL from './images/logo.png'
import Entrance from './images/backdrops/entrance.jpg'
import EntranceM from './images/backdrops/entranceMobile.png'
import Registration from './images/backdrops/register.jpg'
import RegistrationP from './images/backdrops/registerM.jpg'
import LobbyL from './images/backdrops/lobby.jpg'
import LobbyP from './images/backdrops/lobbyM.jpg'
import Exhibition from './images/backdrops/exhibition-hall.jpg'
import Auditorium from './images/backdrops/auditorium.jpg'
import AuditoriumM from './images/backdrops/auditoriumP.jpg'
import NetworkingL from './images/backdrops/networking.jpg'
import LobbyMusic from './images/audio/lobby.mp3'
import routes from './routes'
import Gallerium from './images/backdrops/gallerium.jpg'
import Booth from './images/backdrops/boothBg.png'

const dev = {
  // apiUrl: 'http://localhost:3000',
  apiUrl: 'https://biah-taseen.herokuapp.com',
}

const prod = {
  apiUrl: 'https://biah-taseen.herokuapp.com',
}

const API_KEYS = {
  amplitude: 'ea7d61182f67a4d9f3c4fa1ae70d4f3f',
}

const VOLUME_VALUE = 0.15

const config = process.env.NODE_ENV === 'production' ? prod : dev

const assets = {
  logo: {
    landscape: LogoL,
    portrait: LogoL,
  },

  registration: {
    landscape: Registration,
    portrait: RegistrationP,
  },

  entrance: {
    landscape: Entrance,
    portrait: EntranceM,
  },
  lobby: {
    landscape: LobbyL,
    portrait: LobbyP,
  },
  auditorium1: {
    landscape: Auditorium,
    portrait: AuditoriumM,
  },

  exhibition1: Exhibition,
  exhibition2: Exhibition,

  networking: {
    landscape: NetworkingL,
    portrait: NetworkingL,
  },
  gallerium: Gallerium,

  booth: {
    landscape: Booth,
  },

  lobbyMusic: LobbyMusic,
}

const layout = {
  auditoriumVid: `
    top: 25.5%;
    bottom: 27.5%;
    left: 25.9%;
    right: 29.5%;
  `,
}

const lobbyEntries = [
  {
    id: 'auditorium',
    destination: routes.auditorium1,
    css: `
      top: 53%;
      left: 47%;
      right: 48%;
      bottom: 48.5%;
    `,
  },
  {
    id: 'event',
    destination: [
      { name: 'Photo Voting', url: routes.voting },
      { name: 'Photo Gallery', url: routes.gallerium },
    ],
    css: `
      top: 53%;
      left: 58.9%;
      right: 34%;
      bottom: 48.5%;
    `,
  },
  {
    id: 'booth',
    destination: routes.booth,
    css: `
      top: 53%;
      left: 34%;
      right: 61%;
      bottom: 48%;
    `,
  },
  {
    id: 'helpdesk',
    destination: 0,
    css: `
      top: 85.5%;
      left: 47%;
      right: 48%;
      bottom: 15.5%;
    `,
  },
]

export default {
  API_KEYS,
  assets,
  layout,
  lobbyEntries,
  VOLUME_VALUE,

  eventName: 'BIAH Taseen MY Poultry Platform',
  // googleAnalyticsID: 'UA-185128388-1',

  ...config,
}
