import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyB6CyXbinQojqxf1A1ZWDRdT5AJS5g_fZo",
  authDomain: "bi-poultry.firebaseapp.com",
  databaseURL: "https://bi-poultry-default-rtdb.firebaseio.com",
  projectId: "bi-poultry",
  storageBucket: "bi-poultry.appspot.com",
  messagingSenderId: "558547858659",
  appId: "1:558547858659:web:8f33c22932d01c133a41d6",
  // measurementId: 'G-MYCN9XLCDX',
}

firebase.initializeApp(firebaseConfig)

const firebaseDb = firebase.database()
const firebaseAuth = firebase.auth()

const getDbRef = (dbRef) => {
  const envDbRef = `${process.env.REACT_APP_STAGE === 'production' ? 'production' : 'local'}/${dbRef}`
  return firebaseDb.ref(envDbRef)
}

const ChatDb = getDbRef('chat')
const ChatKfairDb = getDbRef('chatKfair')
const ChatMcDb = getDbRef('chatMc')
const NoticeDb = getDbRef('notice')
const NotificationDb = getDbRef('pushNotifications')
const AccessDb = getDbRef('auditorium')
const VotingAccessDb = getDbRef('voting')

const getUniqueSessionDb = (uid) => getDbRef(`uniqueSession/${uid}`)
const getUserCountDb = (uid, path) => getDbRef(`userCount/${path}/${uid}`)
const getUserSessionDb = (uid) => getDbRef(`userSessions/${uid}`)
const getOnlineStatusDb = (uid) => getDbRef(`onlineStatus/${uid}`)

export { AccessDb, firebaseDb, firebaseAuth, ChatDb, ChatKfairDb, ChatMcDb, NoticeDb, NotificationDb, VotingAccessDb, getUniqueSessionDb, getUserCountDb, getUserSessionDb, getOnlineStatusDb }
