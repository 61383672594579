import React from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

const CustomModal = styled(Modal)`
  .modal-content {
    border-radius: 0px;
    border: none;
    background-color: transparent;
    padding: 0;
    color: #05093A;
  }
  .modal-header {
    border: none;
    padding: 0;
  }
  .close {
    font-size: 40px;
    opacity: 1;
    color: #9AEEBC;
    text-shadow: none;
    &:not(:disabled):hover {
      color: #9AEEBC;
      opacity: 1;
    }
  }
`

export default function PreviewModal({ entry, showModal, closeModal }) {
  return (
    <CustomModal
      show={showModal}
      onHide={closeModal}
      size="md"
      aria-labelledby="message"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <img src={entry.photo.url} width="100%" alt="preview image"/>
      </Modal.Body>
    </CustomModal>
  )
}
