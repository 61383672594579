import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PageWithBg from '../components/PageWithBg'
import config from '../config'
import Amplitude from '../utils/amplitudeHelper'
import poster from '../images/backdrops/voting-bg.jpg'
import routes from '../routes'
import closeIcon from '../images/icons/close-outline.png'
import { Link, useHistory } from 'react-router-dom'
import { SIZES } from '../styles/theme'
import { toast } from 'react-toastify'
import { VotingAccessDb } from '../firebaseConfig'
import { useObject } from 'react-firebase-hooks/database'
import { useSelector, useDispatch } from 'react-redux'
import LoadIcon from '../components/LoadIcon'
import { getFinalists } from '../ducks/entry'
import { EntryVote } from '../components/EntryVote'
import voteBtnHover from '../images/backdrops/vote-btn-hover.png'
import voteBtn from '../images/backdrops/vote-btn.png'
import prevBtn from '../images/backdrops/previous-btn.png'
import nextBtn from '../images/backdrops/next-btn.png'
import PreviewModal from '../components/PreviewModal'

const PageWrapper = styled.div`
  opacity: 0;
  animation: appear 1s forwards;
  #overlay {
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(15px);
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    #poster-container {
      position: absolute;
      /* width: 86.5%; */
      height: 90%;
      margin-top: ${SIZES.navHeight};
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .photo-container {
    position: absolute;
    top: 0%;
    bottom: 12%;
    left: 29.5%;
    right: 4%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    .grid-box {
      padding: 2px;
    }
    p, span {
      margin: 0;
    }
    .entry-details {
      padding: 0 4px;
      text-align: center;
      .entry-title {
        font-weight: 500;
        color: #060738;
        margin: 4px 0;
        font-size: 0.65em;
      }
      .entry-caption {
        color: rgba(0, 0, 0, 0.5);
        font-size: 0.55em;
      }
      @media (min-width: 1600px) {
        .entry-title {
          font-size: 14px;
        }
        .entry-caption {
          font-size: 12px;
        }
      }
      @media (min-width: 2000px) {
        .entry-title {
          font-size: 16px;
        }
        .entry-caption {
          font-size: 14px;
        }
      }
    }
    .participant-details {
      padding: 4px;
      display: flex;
    }
    .entry-order {
      font-size: 30px;
      font-weight: 600;
      color: #9AEEBC;
      margin-right: 4px;
      text-shadow: 2px 2px rgba(0, 0, 0, 0.2);
    }
    .entry-participant {
      color: white;
      text-transform: uppercase;
      font-size: 13px;
      text-shadow: 2px 2px rgba(0, 0, 0, 0.2);
    }

    .photo-box {
      /* height: 100%; */
      height: 31vh;
      width: 95%;
      background-color: #fff;
      margin: 0px auto;
      padding-top: 4px;
      box-shadow: 2px 5px 6px -2px rgba(255, 255, 255, 0.75);
      .entry-photo {
        cursor: pointer;
        position: relative;
        background-position: top center;
        background-size: cover;
        height: 70%;
        max-height: 20vh;
        margin: 0px 4px;
        opacity: 0;
        animation: appear 1s forwards;
      }
    }
  }
  footer {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 89.5%;
    bottom: 0.2%;
    left: 30%;
    right: 26%;
  }
  nav {
    display: flex;
    justify-content: space-between;
    
    img {
      height: 6vh;
      margin: 0 8px;
      cursor: pointer;
    }
  }

  .toggle-back {
    position: absolute;
    top: 10px;
    right: 10px;
    img {
      width: 35px;
    }
  }
  .vote-btn {
    cursor: pointer;
  }
  .pagenumber {
    cursor: pointer;
    color: #FFE400;
    font-size: 17px;
    font-weight: 500;
    margin: 0 5px;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.2);
    opacity: 0.5;
  }
  .active {
    opacity: 1;
  }
`

export default function BestPhotoPage({ currentUser }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const { finalists, requestLoading } = useSelector((state) => state.entry)
  const [snapshot, loading, error] = useObject(VotingAccessDb)
  const [entriesByPage, setEntriesByPage] = useState([])
  const [pageNumber, setPageNumber] = useState(0)
  const [showPreview, setShowPreview] = useState(false)
  const [previewEntry, setPreviewEntry] = useState(null)

  useEffect(() => {
    document.title = `${config.eventName} | Best Photo`
  }, [])

  useEffect(() => {
    if (!loading) {
      if (!snapshot.val() && currentUser.role === 'participant') {
        history.push(routes.lobby)
        toast('Best Photo Voting will be available soon.')
      } else {
        Amplitude.visit('Best Photo')
      }
    }
  }, [loading, snapshot, history, currentUser.id])

  useEffect(() => {
    dispatch(getFinalists())
  }, [dispatch])

  useEffect(() => {
    renderPageEntries(8)
  }, [finalists, requestLoading])

  const renderPageEntries = (entriesPerPage) => {
    let splitArray = [];
    for (let i = 0; i < finalists?.length; i+= entriesPerPage) {
      let newChunk = finalists.slice(i, i+entriesPerPage);
      splitArray.push(newChunk)
    }
    setEntriesByPage(splitArray)
  }
  const previewPhoto = (entry) => {
    setPreviewEntry(entry)
    setShowPreview(true)
  }
  const closePreview = () => {
    setShowPreview(false)
    setPreviewEntry(null)
  }
  const navPrev = () => {
    if (entriesByPage[pageNumber-1] !== undefined) {
      setPageNumber(pageNumber-1)
    }
  }
  const navNext = () => {
    if (entriesByPage[pageNumber+1] !== undefined) {
      setPageNumber(pageNumber+1)
    }
  }

  if (requestLoading) {
    return <LoadIcon />
  }
  return (
    <PageWrapper>
      <PageWithBg bgImg={config.assets.lobby.landscape}>
        <div id="overlay">
          <div id="poster-container">
            <Link to={routes.lobby} className="toggle-back">
              <div className="back-prev">
                <img src={closeIcon} alt="close-icon" />
              </div>
            </Link>
            <div className="photo-container">
              {(entriesByPage && entriesByPage[pageNumber] !=undefined) &&
              entriesByPage[pageNumber].map(entry=>(
                <div className="grid-box" key={`entry-${entry.id}`}>
                  <div className="photo-box">
                    <div onClick={()=>previewPhoto(entry)} className="entry-photo" style={{backgroundImage: `url(${entry.photo.url})`}}>
                      <EntryVote entry={entry} />
                    </div>
                    <div className="entry-details">
                      <p className="entry-title">{entry.photo_title}</p>
                      <p className="entry-caption">{entry.caption}</p>
                    </div>
                  </div>
                  <div className="participant-details">
                    <h6 className="entry-order">{entry.order}</h6>
                    <span className="entry-participant">{entry.participant.fullName}</span>
                  </div>
                </div>
              ))}
            </div>
            <img src={poster} height="100%" alt="contest poster"/>
            <footer>
              <div>
                <img 
                  src={voteBtn} 
                  className="vote-btn"
                  width="200px" 
                  onMouseOver={(e) => (e.currentTarget.src = voteBtnHover)}
                  onMouseOut={(e) => (e.currentTarget.src = voteBtn)}
                  alt="vote button"
                  onClick={()=>history.push(routes.votingform)}
                />
              </div>
              <nav>
                <img src={prevBtn} style={{opacity: `${pageNumber === 0 ? '0' : '1'}`}} onClick={navPrev} alt="navigate button" />
                <div>
                  {[0,1,2,3].map(page=>(
                    <span className={`${pageNumber === page ? 'active' : ''} pagenumber`} onClick={()=>setPageNumber(page)}>{page+1}</span>
                  ))}
                </div>
                <img src={nextBtn} style={{opacity: `${pageNumber === 3 ? '0' : '1'}`}} onClick={navNext} alt="navigate button" />
              </nav>
            </footer>
            {showPreview && <PreviewModal entry={previewEntry} showModal={showPreview} closeModal={closePreview}/>}
          </div>
        </div>
      </PageWithBg>
    </PageWrapper>
  )
}
