import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import EntryForm from '../components/EntryForm'
import PageWithBg from '../components/PageWithBg'
import config from '../config'
import formBg from '../images/backdrops/contest-form.jpg'
import closeIcon from '../images/icons/close.png'
import Amplitude from '../utils/amplitudeHelper'
import routes from '../routes'
import { Link, useHistory } from 'react-router-dom'
import { SIZES } from '../styles/theme'
import { toast } from 'react-toastify'


const PageWrapper = styled.div`
  #overlay {
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(15px);
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    #poster-container {
      position: absolute;
      width: 85%;
      /* top: 50%; */
      margin-top: ${SIZES.navHeight};
      left: 50%;
      transform: translateX(-50%);
      #upload-form {
        position: absolute;
        top: 48%;
        width: 68%;
        left: 50%;
        transform: translateX(-50%);
        /* border: 1px solid red; */
        .message {
          text-align: center;
          background-color: rgba(255, 255, 255, 0.8);
          color: #05093A;
          padding: 36px 24px;
          font-weight: 500;
          width: 90%;
          margin: 0 auto;
          margin-top: 16px;
        }
        
      }
    }
  }

  .toggle-back {
    font-weight: bold;
    color: #fff;
    background: transparent;
    border: none;
    text-decoration: none;
    position: absolute;
    top: 10px;
    right: 10px;
    img {
      filter: invert(1);
      width: 35px;
      opacity: 0.5;
    }
    .back-prev {
      display: flex;
      width: 30px;
      flex-direction: column;
      transition: 2s;
      img {
        width: 26px;
      }
      .icon-label {
        text-align: center;
        font-size: 1px;
        opacity: 0;
      }
      &:hover {
        img {
          transform: scale(0.6);
        }
        .icon-label {
          font-size: 8px;
          opacity: 1;
        }
      }
    }
  }
`

export default function ContestFormPage({ currentUser }) {
  const history = useHistory()
  const [ showSuccess, setShowSuccess ] = useState(false)

  useEffect(() => {
    document.title = `${config.eventName} | Photo Contest`
  }, [])

  useEffect(() => {
    history.push(routes.lobby)
    toast("Photo Contest is now closed. Photo Voting will be available soon.")
    // Amplitude.visit('Contest Form')
  }, [currentUser.id])

  return (
    <PageWrapper>
      <PageWithBg bgImg={config.assets.lobby.landscape}>
        <div id="overlay">
          <div id="poster-container">
            <Link to={routes.lobby} className="toggle-back">
              <div className="back-prev">
                <img src={closeIcon} alt="close-icon" />
                <small className="icon-label">close</small>
              </div>
            </Link>
            <img src={formBg} width="100%" alt="contest poster"/>
            <div id="upload-form" >
            {
              !showSuccess ?
              <>
                <EntryForm setShowSuccess={setShowSuccess} />
              </>
                :
                <div className="message">
                  <h5 className="mb-4 font-weight-bolder">Thank you for your participation.</h5>
                  <p>You will receive a confirmation email if your submission is accepted. </p>
                </div>
              }
          </div>
          </div>
        </div>
      </PageWithBg>
    </PageWrapper>
  )
}
