import React, { useState } from 'react'
import styled from 'styled-components'
import config from '../config'
import Amplitude from '../utils/amplitudeHelper'
import { Form, Row, Col } from 'react-bootstrap'
import Axios from 'axios'
import { toast } from 'react-toastify'
import { SIZES } from '../styles/theme'

const FormWrapper = styled.div`
  .helptext {
    color: white;
    margin: 0;
    font-size: 12px;
    letter-spacing: 1px;
  }
  .claims {
    color: white;
    margin: 0;
    font-size: 9px;
    margin-top: 8px;
    width: 120%;
  }
  .form-group {
    width: 100%;
    margin: 8px auto;
    margin-top: 4px;
    height: 60px;
    background: #FFFFFFD6;
    border: none;
    font-weight: 500;
    text-align: center;
    label {
      color: #000000;
      padding: 0 8px;
      margin: 0 auto;
      padding-right: 0;
      font-size: 13px;
    }
    .fileinput {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
    .fileinput + label {
      cursor: pointer;
    }
    .upload-label {
      align-self: center;
      margin: 4px 8px;
      color: #05093A;
      border-radius: 32px;
      border: 1px solid #01D0B7;
      padding: 2px 4px;
    }
    .image-name {
      color: #05093A;
      width: 265px;
      margin-right: 12px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding: 0px 12px;
      font-size: 14px;
      align-self: center;
    }
    .required:before {
      content:"* ";
      color: red;
    }
    .form-input {
      display: block;
      color: #05093A;
      width: 100%;
      font-weight: 500;
      background: transparent;
      border: none;
      padding: 4px 24px;
      outline: none;
      text-align: center;
      &:focus {
        box-shadow: none;
      }
    }
  }
  #submit-photo {
    background: linear-gradient(#A8FCCA, #01D0B7);
    border-radius: 36px;
    padding: 8px 36px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    border: none;
    &:disabled {
      cursor: not-allowed;
      background: grey;
      opacity: 1;
    }
  }
  ${SIZES.mobile} {
    .form-group label, label.upload-label {
      font-size: 10px;
    }
    .form-group .image-name {
      padding: 0;
      font-size: 12px;
      max-width: 130px;
      margin: 0;
      padding-right: 8px;
    }
    .claims {
      text-align: left;
      width: 100%;
      font-size: 11px;
    }
  }
`
export default function EntryForm({ setShowSuccess }) {
  const [ loading, setLoading ] = useState(false)
  const [ image, setImage ] = useState(null)
  const [ title, setTitle ] = useState("")
  const [ caption, setCaption ] = useState("")

  const handleFileInput = (e) => {
    if (e.target.files[0].size < 1000000) {
      setImage(e.target.files[0])
    } else {
      toast("Please select .png/.jpg less than 1MB.")
    }
  }
  const checkWordsCount = (str) => {
    str = str.replace(/(^\s*)|(\s*$)/gi,"");
    str = str.replace(/[ ]{2,}/gi," ");
    str = str.replace(/\n /,"\n");
    return str.split(' ').length;
  }

  const handleUploadImage = (e) => {
    e.preventDefault()
    setLoading(true)
    let formData = new FormData();
    formData.append("image", image);
    formData.append('photo_title', title);
    formData.append('caption', caption);
    Axios.post(`${config.apiUrl}/api/v1/entries`, formData, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('jwt_token')}`
      }
    })
      .then((resp) => {
        if (resp.data.success) {
          setImage(null)
          setLoading(false)
          setShowSuccess(true)
          Amplitude.submitEntry()
        } else {
          setLoading(false)
          toast(resp.data.error)
        }
      })
      .catch((err) => {
        setLoading(false)
        toast(err.response.data.message)
      })
  }
  const hasEmpty = !(
    image && title.length && caption.length && checkWordsCount(title) < 10 && checkWordsCount(caption) < 15
  )

  return (
    <FormWrapper>
      <p className="text-center helptext">Participant may only submit one photo (entry) in this contest.</p>
      <Row noGutters>
        <Col md={6} className="pr-md-2">
          <Form.Group className="">
            <label className="required">Upload Image (.png / .jpg less than 1MB)</label>
            <div className="d-flex justify-content-between">
              <input id="image-input" type="file" className="form-input fileinput" onChange={handleFileInput} />
              <label for="image-input" className="upload-label">Browse Files</label>
              {image && (<div className="image-name"><span>{image.name}</span></div>)}
            </div>
          </Form.Group>
        </Col>
        <Col md={6} className="pl-md-2">
          <Form.Group>
            <label className="required">Photo Title (less than 10 words):</label>
            <input className="form-input" name="title" onChange={(e)=>setTitle(e.target.value)} value={title}/>
          </Form.Group>
        </Col>
        <Col md={12}>
          <Form.Group>
            <label className="required">Photo Caption (less than 15 words):</label>
            <input className="form-input" name="caption" onChange={(e)=>setCaption(e.target.value)} value={caption}/>
          </Form.Group>
        </Col>
        <Col md={12} className="text-center my-2">
          {checkWordsCount(title) >= 10 && <p className="text-danger m-0">* Photo title must less than 10 words</p>}
          {checkWordsCount(caption) >= 15 && <p className="text-danger m-0">* Photo caption must less than 15 words</p>}
          <button 
            id="submit-photo" 
            disabled={hasEmpty || loading} 
            onClick={handleUploadImage}
          >
            { loading ? 'Submitting...': 'Submit Your Entry' }
          </button>
        </Col>
      </Row>
      <p className="claims">I hereby grant BOEHRINGER INGELHEIM (MALAYSIA) SDN BHD - ANIMAL HEALTH, on free of charge basis the rights to use, translate and distribute any picture taken or provided from me during the Best Match 100 million photo contest, in an internal or external communication of BOEHRINGER INGELHEIM (MALAYSIA) SDN BHD - ANIMAL HEALTH and/or one of its Affiliated Company.</p>
      <p className="claims">Such rights shall be granted worldwide for an unlimited duration unless I advise BOEHRINGER INGELHEIM (MALAYSIA) SDN BHD - ANIMAL HEALTH by written letter to: BOEHRINGER INGELHEIM (MALAYSIA) SDN BHD - ANIMAL HEALTH Suite 15-5 Level 15, Wisma UOA Damansara 2, No.6, Jalan Changkat Semantan, Damansara Heights 50490 Kuala Lumpur, Malaysia.</p>
      <p className="claims">I hereby waive any right to royalties or other compensation arising from or related to the use of the image(s).</p>
    </FormWrapper>
  )
}
