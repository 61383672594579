import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import BgImg from '../../images/backdrops/posterPlainBg.png'
import config from '../../config'
import Amplitude from '../../utils/amplitudeHelper'
import title from '../../images/backdrops/title.png'
import completed from '../../images/backdrops/completedM.png'
import submitBtn from '../../images/backdrops/submit-btn.png'
import routes from '../../routes'
import { toast } from 'react-toastify'
import { useHistory, Link } from 'react-router-dom'
import { useObject } from 'react-firebase-hooks/database'
import { useDispatch, useSelector } from 'react-redux'
import { getFinalists, submitVotes } from '../../ducks/entry'
import { VotingAccessDb } from '../../firebaseConfig'
import LoadIcon from '../../components/LoadIcon'

const MobileVotingForm = ({ currentUser }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { finalists, requestLoading, hasVoted } = useSelector((state) => state.entry)
  const [snapshot, loading, error] = useObject(VotingAccessDb)
  const [votesList, setVotesList] = useState([])

  useEffect(() => {
    document.title = `${config.eventName} | Best Photo Voting`
  }, [])

  useEffect(() => {
    if (!loading) {
      if (!snapshot.val() && currentUser.role === 'participant') {
        history.push(routes.lobby)
        toast('Best Photo Voting will be available soon.')
      } else {
        Amplitude.visit('Voting Page')
      }
    }
  }, [loading, snapshot, history, currentUser.id])

  useEffect(() => {
    if (finalists.length === 0) dispatch(getFinalists())
  }, [dispatch])

  const handleVotes = (entryId) => {
    if (votesList.includes(entryId)) {
      const filtered = votesList.filter(id => id !== entryId)
      setVotesList(filtered)
    } else {
      if (votesList.length < 3) {
        setVotesList([...votesList, entryId])
      } else {
        toast("Only can cast 3 votes, 1 vote per photo.")
      }
    }
  }
  const handleSubmit = () => {
    if (requestLoading) return

    if (votesList.length === 3) {
      dispatch(submitVotes({ entry_ids: votesList}))
    } else {
      toast("Please cast 3 votes, 1 vote per photo.")
    }
  }

  if (requestLoading) {
    return <LoadIcon />
  }
  return (
    <Page>
      <div className="outer-wrapper">
        <div className="container">
          {hasVoted ?
            <>
              <img width="80%" src={completed} id="completed" alt="completed message" />
              <Link to={routes.lobby} id="back-btn" >
                <p>Back To Lobby</p>
              </Link>
            </>
          :
            <>
              <img className="my-5" width="100%" src={title} alt="page-header"/>
              {finalists.map(entry=>(
                <div className="finalist-box" key={`mobile-finalist-${entry.id}`} onClick={()=>handleVotes(entry.id)}>
                  <div className={`${ votesList.includes(entry.id) ? 'selected' : ''} option-box`}>
                    <span className="finalist-order">{entry.order}</span>
                    <span className="finalist-name">{entry.participant.fullName}</span>
                  </div>
                </div>
              ))}
              <div className='my-5' onClick={handleSubmit}>
                <img src={submitBtn} className="submit-btn" width="65%" alt="submit button" />
              </div>
            </>
          }
        </div>
      </div>
    </Page>
  )
}

const Page = styled.div`
  background: url(${BgImg});
  background-size: cover;
  background-position: top center;

  height: calc(100% - 60px);
  width: 100vw;
  border-radius: 0px 0px 25px 25px;
  position: absolute;
  overflow-y: scroll;


  .outer-wrapper {
    width: 100%;
  }
  #back-btn {
    text-decoration: none;
    color: white;
    border: 2px solid #01D0B7;
    border-radius: 24px;
    display: block;
    width: 80%;
    margin: 12px auto;
    p {
      margin: 6px auto;
    }
  }
  
  .container {
    width: 90%;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 36px;
  }
  .finalist-box {
    width: 75%;
    margin: 0 auto;
    margin-bottom: 12px;
  }
  .option-box {
    cursor: pointer;
    display: flex;
    border-radius: 18px;
    padding: 4px 8px;
    text-align: left;
  }
  .selected {
    border: 2px solid #01D0B7;
  }
  .finalist-order {
    font-size: 38px;
    font-weight: 600;
    color: #9AEEBC;
    margin-right: 14px;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.2);
  }
  .finalist-name {
    color: white;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 20px;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.2);
  }
  

  @media screen and (min-width: 540px) {
    .outer-wrapper {
      width: 600px;
      margin: auto;
    }
  }
`

export default MobileVotingForm
