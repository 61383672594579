import React, { useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import agenda from '../../images/event/agenda.jpg'
// import routes from '../../routes'
// import Amplitude from '../../utils/amplitudeHelper'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const MobileMenu = () => {
  const history = useHistory()
  // const [tab, setTab] = useState('agenda')

  return (
    <Page>
      <div className="outer-wrapper">
        <div className="container">
          <div className="title-container">
            <p className="title">Agenda</p>
            <div className="back-button" onClick={()=>history.goBack()}>
              Back
            </div>
          </div>

          <div>
            <LazyLoadImage alt="event-agenda" src={agenda} className="item-image" />
          </div>
        
        </div>
      </div>
    </Page>
  )
}

const Page = styled.div`
  background: white;
  height: calc(100% - 60px);
  width: 100vw;
  overflow-y: scroll;
  border-radius: 0px 0px 25px 25px;
  position: absolute;

  .outer-wrapper {
    width: 100%;
  }

  .container {
    width: 90%;
    margin: auto;
  }

  .title-container {
    margin: 20px 0px 10px 0px;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title {
    width: 70%;
    font-size: 22px;
    font-weight: bold;
    font-style: italic;
    color: black;
    margin: 0;
  }

  .back-button {
    font-size: 12px;
    color: #312384;
    font-weight: bold;
  }

  .profile-container {
    width: 100%;
    height: 100px;
    margin: 10px 0px;
    display: flex;
    align-items: center;
  }

  .profile-image {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-right: 20px;
    /*background: #02c6c0;*/
    background: lightgrey;
  }

  .profile-info-container {
    width: calc(100% - 90px);
    height: 55px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .profile-info-name {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0px;
  }

  .profile-info-edit {
    /*font-size: 12px;*/
    /*font-style: italic;*/
    /*text-decoration: underline;*/
    margin-bottom: 0px;
    /*color: black;*/
    /*display: flex;*/
    /*justify-content: space-between;*/
    width: 100%;
  }

  .profile-info-edit-text {
    font-size: 12px;
    color: black;
    margin-right: 10px;
  }

  .tab-container {
    padding: 5px 0px 20px 0px;
    width: 95%;
    border-bottom: solid 2px #e2cfff;
    margin: auto;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: bold;
    font-style: italic;

    p {
      margin-bottom: 0;
    }

    .active {
      color: #e50000;
    }
  }

  .about-container {
    font-size: 12px;
    text-align: center;
    margin-top: 20px;
  }

  .speaker-day {
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
    margin: 20px 0px;
  }

  .speaker-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .speaker-item-wrapper {
    width: 33.33%;
    display: flex;
    justify-content: center;
  }

  .speaker-item-container {
    width: 70px;
    height: 95px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .speaker-item-image {
    width: 70px;
    height: 70px;
    border-radius: 10px;
    border: solid 2px #662283;
    background: #ede1fe;
  }

  .speaker-item-name {
    font-size: 12px;
    text-align: center;
    margin-bottom: 0;
  }

  .item-image {
    margin-top: 10px;
    width: 100%;
  }

  .avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  @media screen and (min-width: 540px) {
    .outer-wrapper {
      width: 540px;
      margin: auto;
    }
  }
`

export default MobileMenu
