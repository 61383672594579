import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link, useHistory } from 'react-router-dom'
import BgImg from '../../images/backdrops/posterPlainBg.png'
import routes from '../../routes'
import config from '../../config'
import Amplitude from '../../utils/amplitudeHelper'
import logos from '../../images/backdrops/poster-logos.png'
import header from '../../images/backdrops/poster-header.png'
import { useDispatch, useSelector } from 'react-redux'
import { getEntries } from '../../ducks/entry'
import LoadIcon from '../../components/LoadIcon'
import { EntryLike } from '../../components/EntryLike'
import { toast } from 'react-toastify'
import leftIcon from '../../images/event/left.png'
import rightIcon from '../../images/event/right.png'

const MobilePhotoGallery = ({ currentUser }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { entries, requestLoading, likeUpdated } = useSelector((state) => state.entry)
  const [entry, setEntry] = useState(null)
  const [entriesByPage, setEntriesByPage] = useState([])
  const [pageNumber, setPageNumber] = useState(0)

  useEffect(() => {
    Amplitude.visit('Photo Gallery')
  }, [currentUser.id])

  useEffect(() => {
    document.title = `${config.eventName} | Photo Gallery`
  }, [])

  const renderPageEntries = (entriesPerPage) => {
    let splitArray = [];
    for (let i = 0; i < entries?.length; i+= entriesPerPage) {
      let newChunk = entries.slice(i, i+entriesPerPage);
      splitArray.push(newChunk)
    }
    setEntriesByPage(splitArray)
  }

  useEffect(() => {
    dispatch(getEntries())
  }, [dispatch])

  useEffect(() => {
    if (!requestLoading && entry) {
      setEntry(entries.filter(item => item.id == entry.id)[0])
    }
  }, [likeUpdated])

  const handleFocus = (photo) => {
    setEntry(photo)
    renderPageEntries(2)
  }

  const navigateBack = () => {
    if (entriesByPage[pageNumber-1] !== undefined) {
      setPageNumber(pageNumber-1)
    } else {
      toast("First page of gallery")
    }
  }
  const navigateNext = () => {
    if (entriesByPage[pageNumber+1] !== undefined) {
      setPageNumber(pageNumber+1)
    } else {
      toast("Last page of gallery")
    }
  } 
  const closeViewer = () => {
    setEntry(null)
    setPageNumber(0)
  }

  if (requestLoading) {
    return <LoadIcon />
  }
  return (
    <Page>
      <div className="outer-wrapper">
        <div className="container">
          <img className='my-4' width="100%" src={logos} alt="page-header"/>
          <img className='mb-3' width="100%" src={header} alt="page-header"/>
          <div className="gallery">
            <div className={`top-section ${entry ? "mb-3 expand" : "folded"}`}>
              <div className="close-viewer" onClick={closeViewer}>x</div>
              {entry && <img className="image-container" src={entry.photo.url} alt={entry.photo.filename} onClick={closeViewer}/>}
              <div className="like-section">
                <p className="m-0">{entry?.total_likes}</p>
                <div>
                  {entry && <EntryLike entry={entry} />}
                </div>
              </div>
              {entry &&
                <div className="photo-details">
                  <small>{entry.participant.fullName}</small>
                  <p>{entry.photo_title}</p>
                  <small>{entry.caption}</small>
                </div>
              }
            </div>
            <div className={`gallery-container ${entry ? "": "d-none"}`}>
              {(entriesByPage && entriesByPage[pageNumber] !=undefined) && 
              entriesByPage[pageNumber].map((item, index) => 
                <div key={`poster-${item.id}`}>
                  <div className="gallery-item like-button" style={{backgroundImage: `url(${item.photo.url})`}} onClick={()=>handleFocus(item)}>
                    <EntryLike entry={item} />
                  </div>
                </div>
              )}
            </div>
            {entry &&
              <footer>
                <button onClick={navigateBack}>
                  <img src={leftIcon} width="25px" alt="back icon" />
                </button>
                <button onClick={navigateNext}>
                  <img src={rightIcon} width="25px" alt="next icon" />
                </button>
              </footer>
            }

            <div className={`gallery-container ${entry ? "d-none" : ''}`}>
              {entries.map(item =>
                <div key={`entry-${item.photo.filename}-${item.id}`} className="gallery-item like-button" style={{backgroundImage:`url(${item.photo.url})`}} onClick={()=>handleFocus(item)}>
                  <EntryLike entry={item} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Page>
  )
}

const Page = styled.div`
  background: url(${BgImg});
  background-size: cover;
  background-position: top center;

  height: calc(100% - 60px);
  width: 100vw;
  border-radius: 0px 0px 25px 25px;
  position: absolute;
  overflow-y: scroll;


  .outer-wrapper {
    width: 100%;
  }

  .container {
    width: 90%;
    margin: 0 auto;
  }
  .gallery-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 100%;
    width: 100%;
  }
  .gallery-item {
    opacity: 0;
    animation: appear 1s forwards;
    position: relative;
    background-position: center;
    background-size: cover;
    width: 95%;
    height: 88%;
    min-height: 110px;
    max-height: 15vh;
    border-radius: 4px;
    margin: 0 auto;
    margin-bottom: 18px;
    box-shadow: 0px 5px 4px -6px #000;
  }
  .like-button {
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  } 
  .close-viewer {
    position: absolute;
    opacity: 0.7;
    z-index: 100;
    top: -5px;
    left: 2px;
    font-size: 36px;
    line-height: 36px;
    cursor: pointer;
    color: white;
  }
  .like-section {
    background-color: #134068;
    position: absolute;
    top: 12px;
    right: 12px;
    width: 100px;
    padding: 0px 12px;
    display: flex;
    border-radius: 24px;
    color: #fff;
    justify-content: space-around;
    align-items: center;
    svg {
      width: 16px;
    }
  }
  footer {
    width: 90%;
    margin: 16px auto;
    text-align: center;
    margin-top: 4px;
    button {
      background-color: transparent;
      border: none;
      outline: none;
      border-radius: 50%;
    }
  }
  @keyframes grow {
    to { height: 35vh; }
  }
  .expand {
    height: 0;
    animation: grow 300ms forwards;
  }
  .folded {
    background: transparent;
    height: 35vh;
    animation: hide 300ms forwards;
    .imamge-container {
      display: none;
    }
  }
  .top-section {
    background: black;
    border-radius: 4px;
    width: 100%;
    position: relative;
    
    .image-container {
      height: 100%;
      width: 100%;
      display: block;
      margin: 0 auto;
      object-fit: contain;
      opacity: 0;
      animation: appear 1s forwards;
    }
    .photo-details {
      background: rgba(0, 0, 0, 0.3);
      position: absolute;
      bottom: 0;
      padding: 4px 8px;
      width: 100%;
      p, small {
        margin: 0;
        word-break: break-all;
      }
      p {
        font-size: 14px;
        color: #fff;
      }
      small {
        font-size: 10px;
        color: #F0F0F0;
      }
    }
  }
  @media screen and (min-width: 540px) {
    .outer-wrapper {
      width: 600px;
      margin: auto;
    }
  }
`

export { MobilePhotoGallery }
