import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import config from '../config'
import { SIZES } from '../styles/theme'
import EntrancePages from '../containers/EntrancePages'

const Page = styled.div`
  min-height: 100vh;
  width: 100vw;
  text-align: center;

  #content {
    position: absolute;
    top: 0;
    width: 100%;
  }

  #header {
    padding-top: 3vh;
    background: linear-gradient(#ce2440, #ce2440, #800b1e);

    h1 {
      color: #fcfcfc;
      font-weight: 600;
      letter-spacing: 1px;
      margin-bottom: 0.8em;
      font-size: 2rem;
    }

    .time-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      text-transform: uppercase;

      .time-card {
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
        background: rgb(255, 255, 255);
        background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(242, 241, 241, 1) 70%, rgba(180, 179, 179, 1) 100%);

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        min-width: 120px;
        width: 10vw;
        margin: 0 1%;
        margin-top: 5px;
        padding-top: 10px;

        p {
          margin-bottom: 6px;
          font-weight: 500;
        }

        h2 {
          font-weight: 700;
          font-size: 3.2rem;
        }
      }
    }
  }
  .btn {
    display: block;
    margin: 8px 0;
    background: #143e8b;
    color: #ffffff;
    font-weight: 500;
    padding: 4px 24px;
    border-radius: 32px;
    border: 2px solid white;
  }
  .timeup {
    color: white;
    font-weight: 600;
    font-size: 24px;
  }
  #bg {
    background-image: url(${config.assets.entrance.portrait});
    background-size: cover;
    bottom: 0;
    padding: 0;
    min-height: 100%;
    /* height: calc(100vh - 200px); */
    img#portrait {
      display: none;
    }
    img#landscape {
      display: block;
    }
    ${SIZES.mobile} {
      img#portrait {
        display: block;
        margin: 0 auto;
      }
      img#landscape {
        display: none;
      }
    }
  }
`

export default function CountdownPage() {
  const calculateTimeLeft = () => {
    // year, actual month - 1, day, hour, minute, second, ignore
    // 15 Mar 2021 8.30am (8am)
    const difference = +new Date(2021, 2, 15, 8, 0, 0, 0) - +new Date()
    let timeLeft = {}

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      }
    }

    return timeLeft
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    localStorage.clear()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)
  })

  const timerComponents = []

  Object.keys(timeLeft).forEach((interval) => {
    if (isNaN(timeLeft[interval])) {
      return
    }

    timerComponents.push(
      <div className="time-card">
        <p>{interval}</p>
        <h2>{timeLeft[interval]}</h2>
      </div>
    )
  })
  const refreshPage = () => {
    window.location.reload()
  }

  if (!timerComponents.length) {
    return <EntrancePages />
  }

  return (
    <Page>
      <div id="content">
        <div id="header">
          <h1>{config.eventName}</h1>
          <div className="time-wrapper">
            {timerComponents.length ? (
              timerComponents
            ) : (
              <span className="timeup">
                Time's up!
                <button className="btn" onClick={refreshPage}>
                  Enter Event
                </button>
              </span>
            )}
          </div>
        </div>
        <div id="bg">
          <img id="landscape" src={config.assets.entrance.landscape} width="100%" alt="background" />
          <img id="portrait" src={config.assets.entrance.portrait} width="100%" alt="background" />
        </div>
      </div>
    </Page>
  )
}
