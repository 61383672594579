import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PageWithBg from '../components/PageWithBg'
import config from '../config'
import Amplitude from '../utils/amplitudeHelper'
import poster from '../images/backdrops/votingform-bg.jpg'
import title from '../images/backdrops/title.png'
import completed from '../images/backdrops/completed.png'
import submitBtn from '../images/backdrops/submit-btn.png'
import submitBtnHover from '../images/backdrops/submit-btn-hover.png'
import routes from '../routes'
import closeIcon from '../images/icons/close-outline.png'
import { Link, useHistory } from 'react-router-dom'
import { SIZES } from '../styles/theme'
import { toast } from 'react-toastify'
import { VotingAccessDb } from '../firebaseConfig'
import { useObject } from 'react-firebase-hooks/database'
import { useDispatch, useSelector } from 'react-redux'
import { getFinalists, submitVotes } from '../ducks/entry'
import LoadIcon from '../components/LoadIcon'

const PageWrapper = styled.div`
  opacity: 0;
  animation: appear 1s forwards;
  #overlay {
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(15px);
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    #poster-container {
      position: absolute;
      /* width: 85%; */
      height: 90%;
      margin-top: ${SIZES.navHeight};
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .finalist-container {
    position: absolute;
    top: 11%;
    bottom: 10%;
    left: 3%;
    right: 0.5%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
  .finalist-box {
    width: 94%;
    margin-bottom: 8px;
  }
  .option-box {
    cursor: pointer;
    display: flex;
    border-radius: 8px;
    padding: 4px 8px;
  }
  .selected {
    border: 1px solid #01D0B7;
  }

  .toggle-back {
    position: absolute;
    top: 10px;
    right: 10px;
    img {
      width: 35px;
    }
  }
  #page-title {
    width: 25%;
    position: absolute;
    top: 2.5%;
    left: 50%;
    transform: translateX(-50%);
  }
  #completed {
    width: 45%;
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
  }
  .finalist-order {
    font-size: 32px;
    font-weight: 500;
    color: #9AEEBC;
    margin-right: 8px;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.2);
  }
  .finalist-name {
    color: white;
    text-transform: uppercase;
    font-size: 14px;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.2);
  }
  #submit-vote {
    position: absolute;
    bottom: 2%;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
  }
`

export default function VotingPage({ currentUser }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const { finalists, requestLoading, hasVoted } = useSelector((state) => state.entry)
  const [snapshot, loading, error] = useObject(VotingAccessDb)
  const [votesList, setVotesList] = useState([])

  useEffect(() => {
    document.title = `${config.eventName} | Best Photo Voting`
  }, [])

  useEffect(() => {
    if (finalists.length === 0) dispatch(getFinalists())
  }, [dispatch])

  useEffect(() => {
    if (!loading) {
      if (!snapshot.val() && currentUser.role === 'participant') {
        history.push(routes.lobby)
        toast('Best Photo Voting will be available soon.')
      } else {
        Amplitude.visit('Voting Page')
      }
    }
  }, [loading, snapshot, history, currentUser.id])

  const handleVotes = (entryId) => {
    if (votesList.includes(entryId)) {
      const filtered = votesList.filter(id => id !== entryId)
      setVotesList(filtered)
    } else {
      if (votesList.length < 3) {
        setVotesList([...votesList, entryId])
      } else {
        toast("Only can cast 3 votes, 1 vote per photo.")
      }
    }
  }
  const handleSubmit = () => {
    if (requestLoading) return

    if (votesList.length === 3) {
      dispatch(submitVotes({ entry_ids: votesList}))
    } else {
      toast("Please cast 3 votes, 1 vote per photo.")
    }
  }

  if (requestLoading) {
    return <LoadIcon />
  }
  return (
    <PageWrapper>
      <PageWithBg bgImg={config.assets.lobby.landscape}>
        <div id="overlay">
          <div id="poster-container">
            <Link to={routes.voting} className="toggle-back">
              <div className="back-prev">
                <img src={closeIcon} alt="close-icon" />
              </div>
            </Link>
            {hasVoted ?
              <>
                <img src={completed} id="completed" alt="completed message" onClick={()=>history.push(routes.lobby)} />
              </>
            :
              <>
                <img id="page-title" src={title} alt="title" />
                <div className="finalist-container">
                  {finalists.map(entry=>(
                    <div className="finalist-box" key={`finalist-${entry.id}`} onClick={()=>handleVotes(entry.id)}>
                      <div className={`${ votesList.includes(entry.id) ? 'selected' : ''} option-box`}>
                        <span className="finalist-order">{entry.order}</span>
                        <span className="finalist-name">{entry.participant.fullName}</span>
                      </div>
                    </div>
                  ))}
                </div>
                <div id="submit-vote">
                  <img 
                    src={submitBtn} 
                    className="vote-btn"
                    width="200px" 
                    onMouseOver={(e) => (e.currentTarget.src = submitBtnHover)}
                    onMouseOut={(e) => (e.currentTarget.src = submitBtn)}
                    alt="submit button"
                    onClick={handleSubmit}
                  />
                </div>
              </>
            }
            <img src={poster} height="100%" alt="contest poster"/>
          </div>
        </div>
      </PageWithBg>
    </PageWrapper>
  )
}
