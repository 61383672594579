import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import config from '../config'
import routes from '../routes'
import { SIZES } from '../styles/theme'
import { Link, useHistory } from 'react-router-dom'

import PageWithBg from '../components/PageWithBg'
import { getEntries } from '../ducks/entry'
import { useDispatch, useSelector } from 'react-redux'
import { EntryLike } from '../components/EntryLike'
import LoadIcon from '../components/LoadIcon'
import leftIcon from '../images/event/left.png'
import rightIcon from '../images/event/right.png'
import { toast } from 'react-toastify'
import Amplitude from '../utils/amplitudeHelper'

const Page = styled.div`
  .full {
    position: absolute;
    padding-top: ${SIZES.navHeight};
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;

    .sidenavs {
      height: 100%;
      width: 26%;
      display: flex;
      flex-direction: column;
      text-align: center;
      color: white;
    }
    .gallery {
      background-color: rgba(255, 255, 255, 1);
      width: 90%;
      height: 80%;
      border-radius: 4px;
      margin: 0 auto;
      margin-top: 8px;
      margin-right: 78px;
      .bottom-section {
        display: grid;
        grid-template-columns: repeat(4, 1fr) ;
        padding: 16px 16px;
        width: 100%;
        height: 25%;
        .infographic {
          height: 95%;
        }
      }
      .galley-view {
        padding: 16px 16px;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        animation: appear 2s forwards;
      }
      .infographic {
        position: relative;
        background-position: center;
        background-size: cover;
        height: 90%;
        max-height: 20vh;
        border-radius: 4px;
        margin-right: 16px;
        margin-bottom: 32px;
        cursor: zoom-in;
        box-shadow: 0px 5px 4px -6px #000;
        opacity: 0;
        animation: appear 1s forwards;
      }
      .like-button {
        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      } 
    }
    .folded {
      height: 75%;
      animation: hide 400ms forwards;
    }
    .expand {
      height: 0%;
      animation: expand 700ms forwards;
    }
    .top-section {
      background: black;
      border-radius: 4px;
      position: relative;
      img {
        height: 100%;
        display: block;
        margin: 0 auto;
        opacity: 0;
        animation: appear 1s forwards;
      }
      .photo-details {
        background: rgba(0, 0, 0, 0.3);
        position: absolute;
        bottom: 0;
        padding: 8px 24px;
        width: 100%;
        p, small {
          margin: 0;
          word-break: break-all;
        }
        p {
          font-size: 16px;
          color: #fff;
        }
        small {
          font-size: 10px;
          color: #F0F0F0;
        }
      }
    }
    .showcase {
      cursor: zoom-out;
    }
    .close-viewer {
      position: absolute;
      z-index: 100;
      top: -5px;
      left: 2px;
      font-size: 36px;
      line-height: 36px;
      color: white;
      opacity: 0.7;
      cursor: pointer;
    }
    .like-section {
      background-color: #134068;
      position: absolute;
      top: 12px;
      right: 12px;
      width: 100px;
      padding: 0px 12px;
      display: flex;
      border-radius: 24px;
      color: #fff;
      justify-content: space-around;
      align-items: center;
      svg {
        width: 20px;
      }
    }
    .main {
      width: 74%;
      height: 100%;
      
      .toggle-back {
        cursor: pointer;
        background: #394554;
        font-weight: bold;
        color: #01D0B7;
        display: flex;
        align-items: center;
        text-decoration: none;
        padding: 20px 30px;
        .back-icon {
          cursor: pointer;
          display: flex;
          width: 30px;
          flex-direction: column;
          img,
          svg {
            transition: 100ms linear;
            height: 24px;
          }
          .icon-label {
            transition: 100ms linear;
            text-align: left;
            font-size: 1px;
            opacity: 0;
          }
          &:hover {
            img,
            svg {
              transform: scale(0.5);
            }
            .icon-label {
              font-size: 8px;
              opacity: 1;
            }
          }
        }

        span {
          font-size: 24px;
          font-style: italic;
          margin-left: 1em;
        }
      }
    }
  }
  footer {
    width: 90%;
    margin: 0 auto;
    margin-right: 78px;
    text-align: center;
    margin-top: 4px;
    button {
      background-color: transparent;
      border: none;
      outline: none;
      border-radius: 50%;
    }
  }
`

export default function PosterGalleryPage({ currentUser }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { entries, requestLoading, likeUpdated } = useSelector((state) => state.entry)
  const [entry, setEntry] = useState(null)
  const [entriesByPage, setEntriesByPage] = useState([])
  const [pageNumber, setPageNumber] = useState(0)

  useEffect(() => {
    Amplitude.visit('Photo Gallery')
  }, [currentUser.id])

  useEffect(() => {
    document.title = `${config.eventName} | Photo Gallery`
  }, [])

  useEffect(() => {
    dispatch(getEntries())
  }, [dispatch])

  const renderPageEntries = (entriesPerPage) => {
    let splitArray = [];
    for (let i = 0; i < entries?.length; i+= entriesPerPage) {
      let newChunk = entries.slice(i, i+entriesPerPage);
      splitArray.push(newChunk)
    }
    setEntriesByPage(splitArray)
  }

  useEffect(() => {
    renderPageEntries(entry ? 4 : 16)
  }, [entries, requestLoading])

  const closeViewer = () => {
    setEntry(null)
    renderPageEntries(16)
    setPageNumber(0)
  }

  useEffect(() => {
    if (!requestLoading && entry) {
      setEntry(entries.filter(item => item.id == entry.id)[0])
    }
  }, [likeUpdated])

  const handleShowEntry = (selected) => {
    setEntry(selected)
    renderPageEntries(4)
  }

  const navigateBack = () => {
    if (entriesByPage[pageNumber-1] !== undefined) {
      setPageNumber(pageNumber-1)
    } else {
      toast("First page of gallery")
    }
  }
  const navigateNext = () => {
    if (entriesByPage[pageNumber+1] !== undefined) {
      setPageNumber(pageNumber+1)
    } else {
      toast("Last page of gallery")
    }
  }  

  const handleBack = () => {
    if (entry) {
      closeViewer()
    } else {
      history.push(routes.lobby)
    }
  }

  if (requestLoading) {
    return <LoadIcon />
  }
  return (
    <Page>
      <PageWithBg bgImg={config.assets.gallerium}>
        <section className="full">
          <div className="sidenavs"></div>
          <div className="main">
            <div onClick={handleBack} className="toggle-back">
              <div className="back-icon">
                <svg width="17.301" height="26.839" viewBox="0 0 17.301 26.839">
                  <g transform="translate(17.301 26.839) rotate(180)">
                    <path
                      d="M.459,14.462,13.147,26.407a1.637,1.637,0,0,0,2.216,0l1.48-1.393a1.415,1.415,0,0,0,0-2.084L6.79,13.419,16.845,3.909a1.415,1.415,0,0,0,0-2.084L15.363.432a1.637,1.637,0,0,0-2.216,0L.459,12.376A1.415,1.415,0,0,0,.459,14.462Z"
                      transform="translate(17.301 26.839) rotate(180)"
                      fill="#01D0B7"
                    />
                  </g>
                </svg>
                <small className="icon-label">Back</small>
              </div>
              <span>{entry ? "Back" : "Lobby"}</span>
            </div>

            <div className="gallery">
              
              <div className={`top-section ${entry ? "expand" : "folded"}`}>
                <div className="close-viewer" onClick={closeViewer}>x</div>
                {entry && <img className="showcase" src={entry.photo.url} alt={entry.photo.filename} onClick={closeViewer}/>}
                <div className="like-section">
                  <p className="m-0">{entry?.total_likes}</p>
                  <div>
                    {entry && <EntryLike entry={entry} />}
                  </div>
                </div>
                {entry &&
                  <div className="photo-details">
                    <small>{entry.participant.fullName}</small>
                    <p>{entry.photo_title}</p>
                    <small>{entry.caption}</small>
                  </div>
                }
              </div>
              
              <div className={`bottom-section ${entry ? "": "d-none"}`}>
                {(entriesByPage && entriesByPage[pageNumber] !=undefined) && 
                entriesByPage[pageNumber].map((item, index) => 
                  <div key={`poster-${index}`}>
                    <div className="infographic like-button" style={{backgroundImage: `url(${item.photo.url})`}} onClick={()=>handleShowEntry(item)}>
                      <EntryLike entry={item} />
                    </div>
                  </div>
                )}
              </div>

              <div className={`galley-view ${entry ? "d-none" : ""}`}>
                {(entriesByPage && entriesByPage[pageNumber] !=undefined) && 
                entriesByPage[pageNumber].map((item, index) =>
                  <div key={`poster-${index}`}>
                    <div className="infographic like-button" style={{backgroundImage: `url(${item.photo.url})`}} onClick={()=>handleShowEntry(item)}>
                      <EntryLike entry={item} />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <footer>
              <button onClick={navigateBack}>
                <img src={leftIcon} width="25px" alt="back icon" />
              </button>
              <button onClick={navigateNext}>
                <img src={rightIcon} width="25px" alt="next icon" />
              </button>
            </footer>
          </div>
        </section>
      </PageWithBg>
    </Page>
  )
}
