import React from 'react'
import styled from 'styled-components'
import { SIZES } from '../styles/theme'
import Amplitude from '../utils/amplitudeHelper'


const Pointer = styled.div`
  ${(props) => props.css}
  position: absolute;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  img {
    animation: heartbeat 7s infinite;
  }
  .dropdown-toggle {
    box-shadow: none;
    :after {
      content: none;
    }
  }

  ${SIZES.mobile} {
    img {
      width: 28px;
      height: 28px;
    }
  }
`

export default function BoothMaterial({
  id,
  type,
  matLink,
  buttonImg,
  toggleVideoModal,
  toggleInfo,
  css,
}) {
  const handlePdfClick = () => {
    Amplitude.clickHotButtons('pdf')
  }
  return (
    <>
      <Pointer css={css}>
        {
          type === 'pdf' &&
          <a href={matLink} target="_blank" onClick={handlePdfClick} rel="noopener noreferrer">
            <img src={buttonImg} width="40px" height="40px" alt={id} />
          </a>
        }
        {
          type === 'video' &&
          <img src={buttonImg} width="40px" height="40px" onClick={()=>toggleVideoModal(matLink)} alt={id} />
        }
        {
          type === 'contact' &&
          <img src={buttonImg} width="40px" height="40px" onClick={toggleInfo} alt={id} />
        }
        {/* {
          type === 'info' &&
          <img src={buttonImg} width="40px" height="40px" onClick={()=>toggleInfo({matLink,type})} alt={id} />
        } */}
        {/* {
          type === 'website' &&
          <>
            <DropdownButton
              variant="default"
              drop="down"
              title={<img src={buttonImg} width="40px" height="40px" alt={id} />}
            >
              <Dropdown.Header>Company Websites</Dropdown.Header>
              <Dropdown.Item 
                href="https://www.biah-agritech.cloudexpo.my" 
                target="_blank" 
                rel="noopener noreferrer"
                onClick={()=>trackButton(type)}
              >
                https://www.biah-agritech.cloudexpo.my
              </Dropdown.Item>
              <Dropdown.Item 
                href="https://www.sanofi.com.sg" 
                target="_blank" 
                rel="noopener noreferrer"
                onClick={()=>trackButton(type)}
              >
                https://www.sanofi.com.sg
              </Dropdown.Item>
            </DropdownButton>
          </>
        } */}
      </Pointer>
    </>
  )
}