import { firebaseDb, getUserSessionDb } from '../firebaseConfig'
import { useState, useEffect } from 'react'
import { useLocation } from 'react-router'
import firebase from 'firebase/app'

const useUserSession = (currentUser) => {
  const [key, setKey] = useState(null)
  const location = useLocation()
  const pagePath = location.pathname

  useEffect(() => {
    
    const newNode = getUserSessionDb(currentUser.id).push()
    const keyValue = newNode.key
    setKey(keyValue)
    let enterData = {}
    enterData[keyValue] = {
      userId: currentUser.id,
      path: pagePath,
      start: firebase.database.ServerValue.TIMESTAMP,
    }
    if (currentUser && currentUser.id) {
      getUserSessionDb(currentUser.id).update(enterData)
    }

    return () => {
      if (currentUser && currentUser.id) {
        getUserSessionDb(currentUser.id).child(`${keyValue}/end`).set(firebase.database.ServerValue.TIMESTAMP)
  
        getUserSessionDb(currentUser.id).child(`${keyValue}/end`).onDisconnect().cancel()
      }
    }
  }, [pagePath, currentUser.id])

  

  firebaseDb.ref('.info/connected').on('value', snapshot => {
    if (!snapshot.val()) return
    if (!key) return

    if (currentUser && currentUser.id) {
      getUserSessionDb(currentUser.id).child(`${key}/end`).onDisconnect().set(firebase.database.ServerValue.TIMESTAMP)
    }
  })
}


export { useUserSession }
