import download from '../images/booth/download.png'
import call from '../images/booth/call.png'
import info from '../images/booth/info1.png'
import email from '../images/booth/email.png'
import website from '../images/booth/website.png'
import play from '../images/booth/play.png'

export default [
  // {
  //   id: 'website',
  //   buttonImg: website, 
  //   matLink: '',
  //   type: 'website',
  //   css: `
  //     top: 37.5%;
  //     left: 58%;
  //     right: 39%;
  //     bottom: 57%;
  //   `,
  //   mobileCss: `
  //     top: 27.5%;
  //     left: 66%;
  //     right: 31%;
  //     bottom: 67%;
  //   `,
  // },
  // {
  //   id: 'synopsis',
  //   buttonImg: info,
  //   matLink: {
  //     title: 'About Sanofi Malaysia',
  //     text: `Sanofi is dedicated to supporting people through their health challenges. 
  //     Wherever people live and whenever we can make a difference, we seek to protect, 
  //     enable and support those who face health challenges so they can live life to its full potential. 
  //     All that we do is about Empowering Life.`
  //   },
  //   type: 'info',
  //   css: `
  //     top: 19%;
  //     left: 52.5%;
  //     right: 42.5%;
  //     bottom: 73.5%;
  //   `,
  //   mobileCss: `
  //     top: 12%;
  //     left: 23.5%;
  //     right: 72.5%;
  //     bottom: 82.5%;
  //   `,
  // },
  {
    id: 'poster-1',
    buttonImg: download,
    matLink: "https://drive.google.com/file/d/1GvY6mtikiVK0OKQc5hUGN5BRgx1-h1Rs/view?usp=sharing",
    type: 'pdf',
    css: `
      top: 45%;
      left: 24.5%;
      right: 72%;
      bottom: 50%;
    `,
  },
  {
    id: 'poster-2',
    buttonImg: download,
    matLink: "https://drive.google.com/file/d/1XsTKtCzw7KIDFDwwggeoltJjl2mNL3Yy/view?usp=sharing",
    type: 'pdf',
    css: `
      top: 45%;
      left: 31%;
      right: 66.5%;
      bottom: 49.5%;
    `,
  },
  {
    id: 'poster-3',
    buttonImg: download,
    matLink: "https://drive.google.com/file/d/1RzOkQ9KOQax1YoiWVZfKHRGcc4ZjWVm_/view?usp=sharing",
    type: 'pdf',
    css: `
      top: 45%;
      left: 36%;
      right: 59%;
      bottom: 49%;
    `,
  },
  {
    id: 'poster-4',
    buttonImg: download,
    matLink: "https://drive.google.com/file/d/1aEZ8WLLTOepQdDnFZdNlbb4Ik5Kw5Pk3/view?usp=sharing",
    type: 'pdf',
    css: `
      top: 41%;
      left: 72%;
      right: 25%;
      bottom: 53.5%;
    `,
  },
  {
    id: 'video',
    buttonImg: play,
    matLink: '535769850',
    type: 'video',
    css: `
      top: 54.5%;
      left: 60%;
      right: 34%;
      bottom: 40%;
    `,
  },
  {
    id: 'contact',
    buttonImg: call,
    matLink: '',
    type: 'contact',
    css: `
      top: 65%;
      left: 78%;
      right: 19%;
      bottom: 29.5%;
    `,
  },
  // {
  //   id: 'email',
  //   buttonImg: email,
  //   matLink: 'CorpCommsMYSG@sanofi.com',
  //   type: 'contact',
  //   css: `
  //     top: 65%;
  //     left: 73%;
  //     right: 24%;
  //     bottom: 29.5%;
  //   `,
  //   mobileCss: `
  //     top: 65%;
  //     left: 78%;
  //     right: 19%;
  //     bottom: 29.5%;
  //   `,
  // },
]