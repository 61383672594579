import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Amplitude from '../utils/amplitudeHelper'
import PageWithBg from '../components/PageWithBg'
import LoadIcon from '../components/LoadIcon'
import { useDispatch } from 'react-redux'
import { muteMusic, unmuteMusic } from '../ducks/layout'
import config from '../config'
import { SIZES } from '../styles/theme'
import kv from '../images/backdrops/kv.jpg'
import { useObject } from 'react-firebase-hooks/database'
import routes from '../routes'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AccessDb } from '../firebaseConfig'

const Page = styled.div`
  overflow: hidden;
  .trailer {
    background-color: black;
    position: absolute;
    top: 25.5%;
    left: 30.9%;
    right: 34.7%;
    bottom: 54.4%;
    width: 38%;
    height: 45%;
    cursor: pointer;
  }
  .content {
    ${config.layout.auditoriumVid}
    position: absolute;
    background: black;

    iframe {
      height: 100%;
      width: 100%;
    }
  }

  .chat {
    max-width: 350px;
    position: fixed;
    padding-top: ${SIZES.navHeight};
    top: 0;
    left: 71%;
    bottom: 0;
    right: 5%;
    z-index: 30;
  }
`

export default function AuditoriumPage({
  liveUrl,
  hallNumber,
  currentUser,
}) {

  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const history = useHistory()
  const [snapshot, loading, error] = useObject(AccessDb)

  useEffect(() => {
    dispatch(muteMusic())

    return () => {
      if (localStorage.getItem('muted') === 'false') {
        dispatch(unmuteMusic())
      }
    }
  }, [dispatch])

  useEffect(() => {
    document.title = `${config.eventName} | Ballroom`
  }, [])

  useEffect(() => {
    if (!loading) {
      if (!snapshot.val() && currentUser.role === 'participant') {
        history.push(routes.lobby)
        toast('The hall is closed now.')
      } else {
        Amplitude.visit(`Ballroom`)
      }
    }
  }, [loading, snapshot, history, currentUser.id])

  const enterBallroom = () => {
    Amplitude.clickZoomMeeting()
    window.open(
      liveUrl,
      "_blank"
    )
  } 

  if (loading) {
    return <LoadIcon />
  }
  return (
    <Page>
      <PageWithBg bgImg={config.assets.auditorium1.landscape}>
        <div className="trailer">
          <img width="100%" height="100%" src={kv} alt="key-visual" onClick={enterBallroom}/>
        </div>
        <div className="content">
          {loading && <LoadIcon />}
          <iframe
            title="Live Broadcast"
            src={liveUrl}
            allow="autoplay; fullscreen"
            allowFullScreen
            frameBorder="0"
            onLoad={() => {
              setIsLoading(false)
            }}
          />
        </div>
        {/* <div className="chat h-100">
          <iframe
            src="https://app.sli.do/event/08b83bqd" 
            height="100%" 
            width="100%"
            frameBorder="0" 
            style={{ minHeight: '560px'}}
            title="Slido"
          ></iframe>
        </div> */}
      </PageWithBg>
    </Page>
  )
}
