export default {
  root: '/',
  // preRegister: '/registration',
  register: '/registration',
  postRegister: '/registration/complete',
  resetPassword: '/registration/create-password',
  forgotPassword: '/registration/forgot-password',
  login: '/login',

  notifications: '/notifications',
  menu: '/menu',

  lobby: '/lobby',

  networking: '/networking-lounge',
  cloudflix: '/highlights',

  auditorium1: '/auditorium-hall',
  auditorium2: '/mc20-auditorium',

  exhibition1: '/exhibition-hall/1',
  exhibition2: '/exhibition-hall/2',

  gallerium: '/gallery',
  booth: '/booth',
  photo: '/photo-contest',
  photoform: '/photo-contest/form',
  voting: '/best-photo-voting',
  votingform: '/best-photo-voting/form',

}
