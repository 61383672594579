import { useObject } from 'react-firebase-hooks/database'
import { useDispatch } from 'react-redux'
import { kickUser } from '../ducks/auth'
import { firebaseDb, getUniqueSessionDb } from '../firebaseConfig'

function handleDeviceSession(uid) {

  const sessionToken = new Date().toString()
  getUniqueSessionDb(uid).set(sessionToken)
  localStorage.setItem('session_token', sessionToken)
}

function useDuplicateSession(currentUser) {
  const dispatch = useDispatch()
  const sessionRef = getUniqueSessionDb(currentUser.id)

  const [snapshot, loading, error] = useObject(firebaseDb.ref(sessionRef))

  if (!loading && !error && snapshot.val() && snapshot.val() !== localStorage.getItem('session_token')) {
    dispatch(kickUser())
  }
}

export { handleDeviceSession, useDuplicateSession }
